footer {
  width: 100%;
  background-color: var(--main-text);
  bottom: 0;
  left: 0;

  nav {
    margin: 20px 60px;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;

    ul {
      display: flex;
      align-items: center;
      width: 100%;

      li {
        position: relative;
        text-align: left;
      }

      &.nav {
        &-left {
          display: inline-table;
          float: left;
          margin: 0;

          img {
            width: 160px;
            height: 80px;
            object-fit: contain;
          }

          div {
            width: 100%;
            height: 12px;
            font-size: 12px;
            position: relative;
            font-family: Saira;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: #777d95;
          }
        }

        &-right {
          display: inline-table;
          float: left;
          margin: 0;

          li {
            text-align: right;
          }

          span {
            display: block;
            width: 100%;
            height: 20px;
            font-family: Saira;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            font-size: 12px;
            line-height: 2;
            letter-spacing: normal;
            text-align: right;
            color: #777d95;
          }

          img {
            width: 259px;
            position: relative;
            height: 60px;
            object-fit: contain;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  footer nav {
    margin: 20px;
  }

  footer nav ul.nav-left img {
    width: 120px;
    height: 60px;
  }

  footer nav ul.nav-left div {
    font-size: 9px;
  }

  footer nav ul.nav-right img {
    width: 210px;
    height: 45px;
  }

  footer nav ul.nav-right span {
    font-size: 9px;
  }
}

@media screen and (max-width: 600px) {
  footer nav {
    display: flex;
    flex-direction: column-reverse;
    margin: 20px;
  }

  footer nav ul.nav-right {
    margin: auto;
    width: 50%;
    padding-bottom: 20px;
  }

  footer nav ul.nav-left {
    width: 50%;
    margin: auto;
  }

  footer nav ul.nav-left img {
    width: 175px;
    height: 95px;
  }

  footer nav ul li {
    text-align: center;
    width: 100%;
  }

  footer nav ul.nav-right {
    width: 50%;
    padding-bottom: 20px;
  }

  footer nav ul.nav-left div {
    font-size: 12px;
  }
}

@media screen and (max-width: 480px) {
  footer nav ul.nav-left div {
    font-size: 11px;
  }
}
