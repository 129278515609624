.mobile_exclusive {
    &-wrap {
        display: inline;
        flex: auto;
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
    }

    &-containers {
        width: 100%;
        margin: auto;
        padding-top: 20px;

        .title {
            width: 90%;
            margin: auto;

            .heading {
                margin-bottom: 15px;
                color: #26325c;
                font-size: 54px;
                font-family: SairaBold;
                font-weight: bold;
                text-transform: uppercase;
            }

            .sub-heading {
                display: inline-block;
                margin-bottom: 8px;
                padding: 0 16px;
                font-size: 20px;
                letter-spacing: 1.6px;
                line-height: 2;
                color: #fff;
                font-family: SairaMedium;
                background-color: #f4303f;
                text-transform: uppercase;
            }

            button {
                width: 40%;
                height: 64px;
                margin-top: 13px;
                outline: none;
                border: 2px solid #f4303f;
                font-weight: 900;
                background-color: transparent;
                font-family: SairaBold;
                font-size: 24px;
                color: #f4303f;
                text-transform: uppercase;
                cursor: pointer;
            }

        }

        .desc {
            font-size: 17px;
            font-family: SairaMedium;
            color: #26325c;
            text-align: justify;
            line-height: 2;
        }

        .contents {
            overflow: visible;
            box-sizing: border-box;
            padding-top: 30px;
            padding-left: 30px;

            ul {
                align-items: flex-start;
                display: flex;
                flex-wrap: nowrap;
                list-style: none;
                margin: 16px 0;
                overflow-x: scroll;
                scrollbar-width: none;
                width: 100%;

                li {
                    padding: 0 16px 0 0;
                    scroll-snap-align: start;
                    scroll-snap-stop: always;
                    max-width: 256px;
                    width: 50%;
                    height: 350px;
                    transition: all 0.2s ease-in-out;

                    .card {
                        width: 100%;
                        position: relative;

                        img {
                            width: 219px;
                            object-fit: contain;
                        }

                        .new-game {
                            position: absolute;
                            transform: translate(10px, 33.1px);
                            background-image: linear-gradient(#afff5f, #f2f236);
                            padding: 5px;
                            border-bottom-left-radius: 5px;
                            border-bottom-right-radius: 5px;
                            font-weight: 900;
                            text-transform: uppercase;
                        }
                    }

                    .desc {
                        min-height: 175px;
                        padding: 15px;
                        font-size: 12px;
                        box-sizing: border-box;
                        box-shadow: 0 1.5px 15px 0 rgb(152 161 190 / 20%);
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                    }
                }
            }


            ul::-webkit-scrollbar {
                display: none;
            }



        }
    }
}

@media screen and (max-width: 750px) {
    .mobile_exclusive-containers .contents ul li .desc {
        min-height: 160px;
    }

    .exclusive-zh {
        height: 758px;
    }

    .exclusive {
        height: 785px;
    }
}

@media screen and (max-width: 700px) {
    .mobile_exclusive-containers .contents ul li .card img {
        width: 207px;
        object-fit: contain;
    }
    .exclusive-zh {
        padding-top: 35px;
    }
    .exclusive{
        padding-top: 35px;
    }
    .mobile_exclusive-containers .contents ul li .desc {
        min-height: 175px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 31.5px);
    }
}

@media screen and (max-width: 680px) {
    .mobile_exclusive-containers .contents ul li .card img {
        width: 200px;
    }

    .exclusive {
        height: 815px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 30.5px);
    }
}

@media screen and (max-width: 670px) {
    .mobile_exclusive-containers .contents ul li .card img {
        width: 197px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 29.9px);
    }
}

@media screen and (max-width: 650px) {
    .mobile_exclusive-containers .contents ul li .card img {
        width: 193px;
    }

    .mobile_exclusive-containers .contents ul li {
        padding: 0 12px 0 0;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 29.3px);
    }

    .exclusive {
        height: 810px;
    }
}

@media screen and (max-width: 640px) {
    .mobile_exclusive-containers .contents ul li .card img {
        width: 190px;
    }

    .mobile_exclusive-containers .contents ul li .desc {
        font-size: 11px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 28.8px);
        font-size: 12px;
    }
}

@media screen and (max-width: 600px) {
    .mobile_exclusive-containers .contents ul li .card img {
        width: 256px;
    }

    .exclusive-zh {
        height: 790px;
    }

    .mobile_exclusive-containers .contents ul li .desc {
        font-size: 12px;
    }

    .mobile_exclusive-containers .contents ul li {
        padding: 0 16px 0 0;
        height: 410px;
    }

    .exclusive {
        height: 850px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 38.8px);
        font-size: 16px;
    }
}

@media screen and (max-width: 560px) {
    .mobile_exclusive-containers .contents ul li .card img {
        width: 248px;
    }

    .mobile_exclusive-containers .contents ul li .desc {
        font-size: 11px;
        min-height: 146px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 37.7px);
    }
}

@media screen and (max-width: 550px) {
    .mobile_exclusive-containers .contents ul li .card img {
        width: 243px;
    }

    .exclusive-zh {
        height: 755px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 36.8px);
    }
}

@media screen and (max-width: 540px) {
    .mobile_exclusive-containers .contents ul li {
        padding: 0 12px 0 0;
    }

    .mobile_exclusive-containers .contents ul li .card img {
        width: 240px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 36.3px);
    }
}

@media screen and (max-width: 538px) {
    .exclusive-zh {
        height: 790px;
    }
}

@media screen and (max-width: 530px) {
    .mobile_exclusive-containers .contents ul li .card img {
        width: 235px;
    }

    .exclusive-zh {
        height: 715px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 35.5px);
    }

    .mobile_exclusive-containers .title button {
        height: 55px;
        font-size: 20px;
    }

    .mobile_exclusive-containers .title .heading {
        font-size: 50px;
    }

    .mobile_exclusive-containers .title .sub-heading {
        font-size: 18px;
    }

    .mobile_exclusive-containers .desc {
        font-size: 14px;
    }

    .exclusive {
        height: 780px;
    }
}

@media screen and (max-width: 500px) {
    .mobile_exclusive-containers .contents ul li .card img {
        width: 220px;
    }

    .exclusive-zh {
        height: 725px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 33.5px);
        font-size: 12px;
    }

    .mobile_exclusive-containers .contents ul li .desc {
        min-height: 170px;
    }
}

@media screen and (max-width: 480px) {
    .mobile_exclusive-containers .contents ul li .card img {
        width: 218px;
    }

    .exclusive {
        height: 800px;
    }

    .mobile_exclusive-containers .contents {
        padding-left: 15px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 33.2px);
    }
}

@media screen and (max-width: 460px) {
    .mobile_exclusive-containers .contents ul li .card img {
        width: 208px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 31.6px);
    }

    .mobile_exclusive-containers .contents ul li {
        height: 370px;
    }
}


@media screen and (max-width: 440px) {
    .mobile_exclusive-containers .contents ul li .card img {
        width: 198px;
    }

    .exclusive-zh {
        height: 738px;
    }

    .exclusive {
        height: 780px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 30px);
    }
}


@media screen and (max-width: 420px) {
    .mobile_exclusive-containers .contents ul li .card img {
        width: 188px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 28.5px);
    }

}

@media screen and (max-width: 400px) {
    .mobile_exclusive-containers .contents ul li .card img {
        width: 178px;
    }

    .exclusive {
        height: 790px;
    }

    .exclusive-zh {
        height: 720px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 27px);
        font-size: 10px;
    }

    .mobile_exclusive-containers .contents ul li .desc {
        font-size: 10px;
    }
}

@media screen and (max-width: 380px) {
    .mobile_exclusive-containers .desc {
        font-size: 12px;
    }

    .exclusive-zh {
        height: 685px;
    }

    .mobile_exclusive-containers .contents ul li .card img {
        width: 167px;
    }

    .exclusive {
        height: 725px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 25.6px);
        font-size: 9px;
    }

    .mobile_exclusive-containers .title .heading {
        font-size: 45px;
    }

    .mobile_exclusive-containers .title .sub-heading {
        font-size: 15px;
    }

    .mobile_exclusive-containers .title button {
        width: 62%;
    }
}

@media screen and (max-width: 360px) {
    .mobile_exclusive-containers .contents ul li .card img {
        width: 158px;
    }

    .exclusive-zh {
        height: 668px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(10px, 24px);
    }

    .mobile_exclusive-containers .contents ul li .desc {
        min-height: 160px;
        padding: 8px;
    }

    .exclusive {
        height: 705px;
    }
}

@media screen and (max-width: 340px) {
    .mobile_exclusive-containers .contents ul li .card img {
        width: 148px;
    }

    .exclusive-zh {
        height: 655px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(5px, 22.5px);
    }

    .exclusive {
        height: 720px;
    }
}


@media screen and (max-width: 320px) {
    .mobile_exclusive-containers .contents ul li .desc {
        min-height: 150px;
        padding: 5px;
    }
    .exclusive-zh {
        height: 635px;    
        padding-top: 20px;
    }
    .mobile_exclusive-containers .contents ul li .card img {
        width: 137px;
    }

    .exclusive {
        height: 702px;
        padding-top: 20px;
    }

    .mobile_exclusive-containers .contents ul li .card .new-game {
        transform: translate(5px, 20.7px);
        font-size: 8px;
    }
}