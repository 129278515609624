// responsive
@media screen and (max-width: 1536px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 542px !important;
    left: 25px;
    width: 90% !important;
  }

  .gallery-slide-wrap {
    max-width: 1450px;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    width: 88% !important;
    left: 47px;
    top: 37px;
    height: 461px !important;
  }

  .gallery-slide-wrap .inlines .heading {
    width: 90%;
  }
}

@media screen and (max-width: 1440px) {
  .gallery-slide-wrap {
    max-width: 1360px;
  }

  .gallery-slide-wrap .inlines .event-name {
    width: 65%;
    left: 30px;
    position: relative;
  }

  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 475px !important;
    width: 90% !important;
    left: 50px;
  }

  .gallery-slide-wrap .inlines .inline-image {
    height: 490px;
  }

  .gallery-slide-wrap .inlines .heading {
    margin-bottom: 100px;
    font-size: 54px;
    width: 80%;
    transform: translate(0px, 70px);
  }

  .gallery-slide-wrap .inlines .sub-heading {
    font-size: 22px;
  }

  .gallery-slide-wrap .inlines .sub-heading-two {
    font-size: 22px;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    width: 89% !important;
    height: 400px !important;
    left: 53px;
  }
}

.gallery-slide-wrap .slick-prev {
  width: 60px;
  height: 60px;
  transform: translateX(-35px);
}

.gallery-slide-wrap .slick-next {
  width: 60px;
  height: 60px;
  transform: translateX(35px);
}

@media screen and (max-width: 1350px) {
  .gallery-slide-wrap .slick-next {
    right: 100px !important;
  }

  .gallery-slide-wrap .slick-prev {
    left: 60px !important;
    z-index: 4;
  }

  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 445px !important;
    width: 85% !important;
    left: 60px;
  }

  .gallery-slide-wrap .inlines .heading {
    font-size: 50px;
  }

  .gallery-slide-wrap .inlines .photo-link {
    top: 12%;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    width: 86% !important;
    left: 60px;
  }

  .gallery-slide-wrap .inlines .inline-image {
    height: 440px;
  }
}

@media screen and (max-width: 1300px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 430px !important;
  }

  .gallery-slide-wrap .inlines .sub-heading {
    font-size: 20px;
  }

  .gallery-slide-wrap .inlines .sub-heading-two {
    font-size: 20px;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    width: 85% !important;
  }
}

@media screen and (max-width: 1280px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 425px !important;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    top: 32px;
    height: 359px !important;
  }
}

@media screen and (max-width: 1250px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 410px !important;
  }

  .gallery-slide-wrap .slick-prev {
    top: 45% !important;
  }

  .gallery-slide-wrap .slick-next {
    top: 45% !important;
  }

  .gallery-slide-wrap .inlines .photo-link {
    top: 8%;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    height: 347px !important;
  }
}

@media screen and (max-width: 1200px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 396px !important;
  }

  .gallery-slide-wrap .inlines .heading {
    font-size: 45px;
  }

  .gallery-slide-wrap .inlines .photo-link {
    top: 9%;
    font-size: 14px;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    height: 332px !important;
  }
}

@media screen and (max-width: 1150px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 377px !important;
  }

  .gallery-slide-wrap .inlines .sub-heading-two {
    font-size: 16px;
  }

  .gallery-slide-wrap .inlines .sub-heading {
    font-size: 16px;
  }

  .gallery-slide-wrap .inlines .photo-link {
    top: 7%;
  }

  .gallery-slide-wrap .slick-prev {
    top: 40% !important;
  }

  .gallery-slide-wrap .slick-next {
    top: 40% !important;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    height: 315px !important;
  }
}

@media screen and (max-width: 1100px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 360px !important;
  }

  .gallery-slide-wrap .inlines .heading {
    font-size: 40px;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    top: 24px;
    height: 310px !important;
  }

  .gallery-slide-wrap .inlines .inline-image {
    height: 370px;
  }

  // .slick-prev,
  // .slick-next {
  //     transform: translate(0, 40px) !important;
  // }
}

@media screen and (max-width: 1080px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 353px !important;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    height: 300px !important;
  }
}

@media screen and (max-width: 1024px) {
  // .gallery-slide-wrap .slick-prev:before {
  //     background-size: 85%;
  // }

  .gallery-slide-wrap {
    padding: 40px 0;
  }

  // .gallery-slide-wrap .slick-next:before {
  //     background-size: 85%;
  // }

  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 320px !important;
    width: 81% !important;
  }

  .gallery-slide-wrap .inlines .inline-image .play-btn {
    width: 95px;
  }

  .gallery-slide-wrap .inlines .heading {
    font-size: 39px;
    transform: translate(0px, 50px);
    margin-bottom: 60px;
  }

  .gallery-slide-wrap .slick-prev {
    top: 36% !important;
  }

  .gallery-slide-wrap .slick-next {
    top: 36% !important;
  }

  .gallery-slide {
    height: 410px;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    width: 81% !important;
    height: 265px !important;
  }

  .modal .modal-content {
    max-width: 700px;
  }

  .modal .modal-content .modal-header .btn-close {
    transform: translate(5px, 15px);
  }

  .modal .modal-body img:last-child {
    height: 500px;
    object-fit: contain;
  }
}

@media screen and (max-width: 1000px) {
  .modal .modal-body img:last-child {
    height: 450px;
  }
}

@media screen and (max-width: 950px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 295px !important;
  }

  .gallery-slide-wrap .inlines .inline-image {
    height: 320px;
  }

  .gallery-slide-wrap .inlines .event-name {
    left: 0px;
  }

  .gallery-slide-wrap .inlines .heading {
    font-size: 35px;
    transform: translate(0px, 40px);
  }

  .gallery-slide-wrap .inlines .sub-heading {
    font-size: 14px;
  }

  .gallery-slide-wrap .inlines .sub-heading-two {
    font-size: 14px;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    height: 252px !important;
    top: 20px;
  }
}

@media screen and (max-width: 900px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 280px !important;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    width: 81% !important;
    height: 244px !important;
    top: 15px;
  }
}

@media screen and (max-width: 850px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 235px !important;
    width: 80% !important;
  }

  // .gallery-slide-wrap .slick-prev:before {
  //     background-size: 70%;
  // }

  .gallery-slide-wrap .slick-prev {
    top: 27% !important;
  }

  .gallery-slide-wrap .slick-next {
    top: 27% !important;
    right: 70px !important;
  }

  // .gallery-slide-wrap .slick-next:before {
  //     background-size: 70%;
  // }

  .gallery-slide-wrap .inlines .heading {
    font-size: 30px;
    transform: translate(0px, 35px);
    margin-bottom: 43px;
  }

  .gallery-slide-wrap .inlines .sub-heading {
    font-size: 13px;
  }

  .gallery-slide-wrap .inlines .inline-image {
    right: 20px;
  }

  .gallery-slide-wrap .inlines .photo-link {
    top: 3%;
    font-size: 10px;
  }

  .gallery-slide {
    height: 330px;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    width: 75% !important;
    height: 200px !important;
    left: 71px;
  }

  .gallery-slide-wrap .inlines .event-name {
    left: -15px;
  }
}

// mobile view
@media screen and (max-width: 750px) {
  .gallery-slide {
    height: 790px;
  }

  .modal .modal-body img:last-child {
    height: 365px;
  }

  .gallery-slide-wrap {
    padding: 70px 0;
  }

  .gallery-slide-wrap .inlines {
    display: table !important;
    width: 100% !important;
  }

  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 426px !important;
    width: 100% !important;
    left: 0px;
  }

  .gallery-slide-wrap .slick-slider .slick-track,
  .gallery-slide-wrap .slick-slider .slick-list {
    width: 100%;
    left: 0px;
  }

  .gallery-slide-wrap .inlines .inline-image {
    right: 0px;
  }

  .gallery-slide-wrap .inlines .inline-image {
    height: 460px;
    width: 100%;
  }

  .gallery-slide-wrap .slick-prev {
    top: 49% !important;
    left: 44% !important;
  }

  .gallery-slide-wrap .slick-next {
    top: 49% !important;
    right: 47% !important;
  }

  .gallery-slide-wrap .slick-initialized .slick-slide {
    left: 0px;
  }

  .gallery-slide-wrap .inlines .event-name {
    width: 70%;
    margin: auto;
    height: 400px;
    left: 0px;
  }

  .gallery-slide-wrap .inlines .heading {
    width: 100%;
    font-size: 56px;
    text-align: center;
    margin-bottom: 50px;
  }

  .gallery-slide-wrap .inlines .sub-heading {
    font-size: 18px;
    display: flex;
    margin-bottom: 7px;
    margin: auto;
    width: 45%;
    justify-content: center;
  }

  .gallery-slide-wrap .inlines .sub-heading-two {
    font-size: 18px;
    display: table;
    margin: auto;
    width: 45%;
    position: relative;
    top: 6px;
    text-align: center;
  }

  .gallery-slide-wrap .inlines .photo-link {
    top: 25px;
    position: relative;
    font-size: 20px;
    margin: auto;
    width: 43%;
    text-align: center;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    width: 90% !important;
    height: 422px !important;
    margin: auto;
    left: 36px;
    top: 0px;
  }

  .modal .modal-content {
    width: 80%;
  }

  .modal .modal-content .modal-header .btn-close {
    transform: translate(3px, 17px);
  }

  .modal .modal-dialog-centered {
    min-height: calc(100% - 200px);
  }
}

@media screen and (max-width: 700px) {
  .gallery-slide-wrap .inlines .heading {
    font-size: 55px;
  }

  .modal .modal-body {
    padding: 0px !important;
    top: 30px;
  }

  .gallery-slide-wrap .inlines .sub-heading {
    font-size: 17px;
  }

  .gallery-slide-wrap .inlines .photo-link {
    font-size: 19px;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    height: 393px !important;
    top: 15px;
  }

  .modal .modal-content .modal-header .btn-close {
    transform: translate(3px, 30px);
  }

  .modal .modal-body img:last-child {
    height: 340px;
  }
}

@media screen and (max-width: 680px) {
  .gallery-slide-wrap .inlines .heading {
    font-size: 53px;
  }

  .gallery-slide-wrap .inlines .sub-heading {
    font-size: 16px;
  }

  .gallery-slide-wrap .inlines .sub-heading-two {
    font-size: 16px;
  }

  .gallery-slide-wrap .inlines .photo-link {
    font-size: 18px;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    height: 382px !important;
    top: 20px;
  }

  .gallery-slide-wrap .inlines .inline-image {
    height: 430px;
  }

  .gallery-slide {
    height: 740px;
  }

  .modal .modal-content .modal-header .btn-close {
    transform: translate(8px, 30px);
  }
}

@media screen and (max-width: 650px) {
  .gallery-slide-wrap .inlines .inline-image video {
    height: 364px !important;
    top: 29px;
  }

  .gallery-slide {
    height: 720px;
  }

  .modal .modal-content .modal-header .btn-close {
    transform: translate(18px, 30px);
  }

  .gallery-slide-wrap {
    padding: 50px 0;
  }

  .gallery-slide-wrap .inlines .heading {
    font-size: 50px;
  }

  .gallery-slide-wrap .inlines .sub-heading {
    font-size: 15px;
  }

  .gallery-slide-wrap .inlines .sub-heading-two {
    font-size: 15px;
  }

  .gallery-slide-wrap .inlines .photo-link {
    font-size: 17px;
  }

  .gallery-slide-wrap .inlines .event-name {
    height: 300px;
  }

  .gallery-slide-wrap .slick-prev {
    top: 54% !important;
  }

  .gallery-slide-wrap .slick-next {
    top: 54% !important;
  }
}

@media screen and (max-width: 600px) {
  .gallery-slide-wrap .inlines .heading {
    font-size: 47px;
  }

  .gallery-slide-wrap .inlines .sub-heading {
    font-size: 14px;
  }

  .gallery-slide-wrap .inlines .sub-heading-two {
    font-size: 14px;
  }

  .gallery-slide-wrap .inlines .photo-link {
    font-size: 16px;
  }

  .gallery-slide-wrap .slick-prev {
    left: 42% !important;
    top: 52% !important;
  }

  .gallery-slide-wrap .slick-next {
    right: 45% !important;
    top: 52% !important;
  }

  .modal .modal-content {
    width: 85%;
  }

  .modal .modal-content .modal-header .btn-close {
    transform: translate(18px, 35px);
  }

  .gallery-slide-wrap {
    width: 90%;
  }

  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 390px !important;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    height: 331px !important;
    top: 28px;
    width: 99% !important;
    left: 3px;
  }

  .gallery-slide-wrap .inlines .inline-image {
    height: 390px;
  }

  .gallery-slide-wrap .inlines .event-name {
    width: 80%;
  }

  .gallery-slide {
    height: 670px;
  }
}

@media screen and (max-width: 580px) {
  .modal .modal-content .modal-header .btn-close {
    transform: translate(18px, 40px);
  }
}

@media screen and (max-width: 550px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 355px !important;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    height: 303px !important;
    top: 24px;
  }

  .gallery-slide-wrap .inlines .inline-image {
    height: 350px;
  }

  .gallery-slide-wrap .slick-prev {
    top: 50% !important;
  }

  .gallery-slide-wrap .slick-next {
    top: 50% !important;
  }

  .gallery-slide-wrap .inlines .event-name {
    width: 85%;
  }

  .gallery-slide {
    height: 630px;
  }

  .modal .modal-body img:last-child {
    height: 290px;
  }

  .modal .modal-content .modal-header .btn-close {
    transform: translate(10px, 30px);
  }
}

@media screen and (max-width: 530px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 343px !important;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    height: 292px !important;
    top: 24px;
  }

  .gallery-slide-wrap .inlines .heading {
    font-size: 40px;
  }

  .modal .modal-content .modal-header .btn-close {
    transform: translate(16px, 33px);
  }

  .gallery-slide-wrap .inlines .sub-heading {
    font-size: 13px;
  }

  .gallery-slide-wrap .inlines .sub-heading-two {
    font-size: 13px;
  }

  .gallery-slide-wrap .inlines .photo-link {
    font-size: 15px;
  }

  .gallery-slide {
    height: 620px;
  }
}

@media screen and (max-width: 500px) {
  .modal .modal-content .modal-header .btn-close {
    transform: translate(16px, 40px);
  }

  .gallery-slide-wrap .inlines .photo-link {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 310px !important;
  }

  .modal .modal-body img:last-child {
    height: 230px;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    height: 265px !important;
    top: 21px;
  }

  .gallery-slide-wrap .inlines .event-name {
    height: 240px;
  }

  .gallery-slide-wrap .inlines .heading {
    margin-bottom: 25px;
    transform: translate(0px, 14px);
  }

  .gallery-slide-wrap .inlines .sub-heading {
    font-size: 12px;
  }

  .gallery-slide-wrap .inlines .sub-heading-two {
    font-size: 12px;
  }

  .gallery-slide-wrap .inlines .photo-link {
    font-size: 13px;
  }

  .gallery-slide {
    height: 580px;
  }

  .gallery-slide-wrap .slick-prev {
    left: 40% !important;
  }

  .modal .modal-content .modal-header .btn-close {
    transform: translate(0px, 33px);
  }
}

@media screen and (max-width: 450px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 287px !important;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    height: 243px !important;
  }

  .gallery-slide-wrap .inlines .inline-image {
    height: 295px;
  }

  .gallery-slide-wrap .inlines .event-name {
    width: 92%;
  }

  .gallery-slide-wrap .inlines .heading {
    margin-bottom: 35px;
    transform: translate(0px, 30px);
  }

  .modal .modal-content .modal-header .btn-close {
    transform: translate(5px, 33px);
  }

  .gallery-slide {
    height: 540px;
  }
}

@media screen and (max-width: 430px) {
  .modal .modal-content .modal-header .btn-close {
    transform: translate(12px, 33px);
  }
}

@media screen and (max-width: 400px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 257px !important;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    height: 219px !important;
    top: 18px;
  }

  .gallery-slide-wrap .inlines .inline-image {
    height: 240px;
  }

  .gallery-slide-wrap .inlines .heading {
    font-size: 30px;
    transform: translate(0px, 55px);
    margin-bottom: 60px;
  }

  .modal .modal-dialog-centered {
    min-height: calc(100% - 150px);
  }

  // .gallery-slide-wrap .slick-prev:before {
  //     background-size: 60%;
  // }

  // .gallery-slide-wrap .slick-next:before {
  //     background-size: 60%;
  // }

  .gallery-slide-wrap .inlines .sub-heading {
    font-size: 10px;
  }

  .gallery-slide-wrap .inlines .sub-heading-two {
    font-size: 10px;
  }

  .gallery-slide-wrap .inlines .photo-link {
    font-size: 12px;
  }

  .gallery-slide {
    height: 490px;
  }
}

@media screen and (max-width: 400px) {
  .modal .modal-content .modal-header .btn-close {
    transform: translate(16px, 42px);
  }
}

@media screen and (max-width: 380px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 243px !important;
  }

  .modal .modal-content .modal-header .btn-close {
    transform: translate(15px, 47px);
  }

  .gallery-slide-wrap .inlines .inline-image video {
    height: 206px !important;
  }

  .gallery-slide-wrap .slick-prev {
    top: 50% !important;
    left: 36% !important;
  }

  .gallery-slide-wrap .slick-next {
    top: 50% !important;
  }

  .gallery-slide-wrap .inlines .heading {
    font-size: 28px;
    transform: translate(0px, 40px);
    margin-bottom: 45px;
  }

  .gallery-slide-wrap .inlines .event-name {
    width: 80%;
  }

  .gallery-slide-wrap .inlines .sub-heading {
    font-size: 8px;
  }

  .gallery-slide-wrap .inlines .sub-heading-two {
    font-size: 8px;
  }

  .gallery-slide-wrap .inlines .photo-link {
    font-size: 10px;
  }

  .gallery-slide-wrap .inlines .event-name {
    height: 200px;
  }

  .gallery-slide {
    height: 460px;
  }
}

@media screen and (max-width: 360px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 230px !important;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    height: 196px !important;
    top: 16px;
  }

  .gallery-slide-wrap .inlines .event-name {
    width: 84%;
  }

  .gallery-slide-wrap .inlines .photo-link {
    width: 45%;
  }

  .gallery-slide-wrap {
    padding: 40px 0;
  }

  .gallery-slide {
    height: 450px;
  }


  .modal .modal-content .modal-header .btn-close {
    transform: translate(9px, 32px);
    height: 10px;
  }

  .modal .modal-body img:last-child {
    height: 195px;
  }
}

@media screen and (max-width: 320px) {
  .gallery-slide-wrap .inlines .inline-image .react-player__preview {
    height: 200px !important;
  }

  .gallery-slide-wrap .inlines .inline-image .play-btn {
    width: 70px;
  }

  .gallery-slide-wrap .inlines .inline-image {
    height: 210px;
  }

  .gallery-slide-wrap .inlines .heading {
    font-size: 25px;
  }

  .gallery-slide-wrap .inlines .event-name {
    width: 95%;
  }

  .gallery-slide-wrap .slick-prev {
    top: 45% !important;
  }

  .gallery-slide-wrap .slick-next {
    top: 45% !important;
  }

  .modal .modal-content .modal-header .btn-close {
    transform: translate(15px, 35px);
    height: 10px;
  }

  .gallery-slide-wrap .inlines .inline-image video {
    height: 168px !important;
  }

  .gallery-slide {
    height: 420px;
  }
}