.error-container {
    background-image: url('/assets/images/bg-404.png');
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;

    &wrap {
        display: table;
        position: fixed;
        width: 100%;
        top: 210px;

    }

    img {
        object-fit: contain;
        width: 100%;
        height: 800px;
        position: fixed
    }

    span {
        color: #f4303f;
        text-align: center;
        font-size: 92px;
        font-family: SairaBold;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.58;
        letter-spacing: normal;
        position: relative;
        top: 5px;
        text-transform: uppercase;
    }

    .sub-headings {
        font-family: SairaBold;
        font-size: 25px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        position: relative;
        top: 175px;
        text-transform: uppercase;
    }

    .zh-size {
        font-size: 50px;
        top: 60px;
        font-family: SairaBold;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        position: relative;
        text-transform: uppercase;
    }

    .center {
        display: table-row;
        margin: auto;
        position: fixed;
        width: 100%;
        top: 280px;
        text-align: center;
    }

    .zh-center {
        display: table-row;
        margin: auto;
        position: fixed;
        width: 100%;
        top: 360px;
        text-align: center;
    }

    .btn-center {
        display: grid;
        position: fixed;
        text-align: center;
        margin: auto;
        width: 100%;

        .btn-gotohome {
            padding: 14px;
            width: 17%;
            background: transparent;
            border: 2px solid #f4303f;
            margin: auto;
            text-align: center;
            margin-top: 256px;

            a {
                color: #f4303f;
                font-weight: 800;
                text-transform: uppercase;
                font-size: 27px;
            }
        }

        .zh-btn {
            width: 18%;
            padding: 14px;
            background: transparent;
            border: 2px solid #f4303f;
            margin: auto;
            text-align: center;
            margin-top: 180px;

            a {
                color: #f4303f;
                font-weight: 800;
                text-transform: uppercase;
                font-size: 35px;
            }
        }
    }
}

// mobile view
.error-container-mobile {
    background-image: url('/assets/images/bg-404-mobile.png');
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;

    &wrap {
        display: table;
        position: fixed;
        width: 100%;
        top: 210px;

    }

    img {
        object-fit: contain;
        width: 100%;
        height: 800px;
        position: fixed
    }

    span {
        color: #f4303f;
        text-align: center;
        font-size: 92px;
        font-family: SairaBold;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.58;
        letter-spacing: normal;
        position: relative;
        top: 5px;
        text-transform: uppercase;
    }

    .sub-headings {
        font-family: SairaBold;
        font-size: 25px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        position: relative;
        top: 175px;
        text-transform: uppercase;
    }

    .zh-size {
        font-size: 50px;
        top: 60px;
        font-family: SairaBold;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        position: relative;
        text-transform: uppercase;
    }

    .center {
        display: table-row;
        margin: auto;
        position: fixed;
        width: 100%;
        top: 280px;
        text-align: center;
    }

    .zh-center {
        display: table-row;
        margin: auto;
        position: fixed;
        width: 100%;
        top: 360px;
        text-align: center;
    }

    .btn-center {
        display: grid;
        position: fixed;
        text-align: center;
        margin: auto;
        width: 100%;

        .btn-gotohome {
            padding: 14px;
            width: 17%;
            background: transparent;
            border: 2px solid #f4303f;
            margin: auto;
            text-align: center;
            margin-top: 256px;
            cursor: pointer;
            transition: 0.1s;

            a {
                color: #f4303f;
                font-weight: 800;
                text-transform: uppercase;
                font-size: 27px;
            }
        }

        .btn-gotohome:hover {
            box-shadow: 1000px 0 0 0 #f4303f inset;
            color: #fff;
        }

        .zh-btn {
            width: 18%;
            padding: 14px;
            background: transparent;
            border: 2px solid #f4303f;
            margin: auto;
            text-align: center;
            margin-top: 180px;
            cursor: pointer;
            transition: 0.1s;

            a {
                color: #f4303f;
                font-weight: 800;
                text-transform: uppercase;
                font-size: 35px;
            }
        }
        &:hover {
            box-shadow: 1000px 0 0 0 #f4303f inset;
            color: #fff;
        }

    }
}

@media screen and (max-width: 1536px) {

    .error-container .btn-center .zh-btn a {
        font-size: 24px;
    }

    .error-container .zh-center {
        top: 285px;
    }

    .error-container .zh-size {
        font-size: 40px;
    }

    .error-container .btn-center .zh-btn {
        margin-top: 160px;
        width: 18%;
    }

    .error-container .center {
        top: 225px;
    }

    .error-container span {
        font-size: 75px;
    }

    .error-container .sub-headings {
        font-size: 20px;
        top: 135px;
    }

    .error-container .btn-center .btn-gotohome {
        padding: 10px;
        width: 18%;
        margin-top: 200px;
    }

    .error-container .btn-center .btn-gotohome a {
        font-size: 23px
    }
}

@media screen and (max-width: 1024px) {
    .error-container .zh-size {
        font-size: 38px;
    }

    .error-container .zh-center {
        top: 270px;
    }

    .error-container .btn-center .zh-btn {
        margin-top: 155px;
        width: 25%;
    }

    .error-container .btn-center .zh-btn a {
        font-size: 20px;
    }

    .error-container span {
        font-size: 70px;
    }

    .error-container .sub-headings {
        font-size: 18px;
        top: 125px;
    }

    .error-container .btn-center .btn-gotohome {
        margin-top: 195px;
    }

    .error-container .btn-center .btn-gotohome a {
        font-size: 15px;
    }
}

@media screen and (max-width: 980px) {
    .error-container .btn-center .btn-gotohome {
        width: 20%;
    }
}

@media screen and (max-width: 880px) {
    .error-container .btn-center .btn-gotohome {
        width: 21%;
    }
}

@media screen and (max-width: 840px) {
    .error-container .btn-center .btn-gotohome a {
        font-size: 14px;
    }

    .error-container .zh-size {
        font-size: 30px;
        top: 20px;
    }

    .error-container .btn-center .zh-btn {
        margin-top: 110px;
        width: 25%;
    }

    .error-container span {
        font-size: 65px;
    }

    .error-container .sub-headings {
        font-size: 17px;
        top: 117px;
    }

    .error-container .btn-center .btn-gotohome {
        margin-top: 180px;
        width: 22%;
    }
}

@media screen and (max-width: 750px) {
    .error-container span {
        font-size: 60px;
    }

    .error-container .sub-headings {
        font-size: 16px;
    }

    .error-container .btn-center .btn-gotohome a {
        font-size: 13px;
    }
}

@media screen and (max-width: 700px) {
    .error-container .btn-center .btn-gotohome {
        width: 27%;
    }

    .error-container .zh-size {
        font-size: 27px;
    }

    .error-container .btn-center .zh-btn {
        margin-top: 95px;
        padding: 10px;
        width: 25%;
    }

    .error-container .btn-center .zh-btn a {
        font-size: 18px;
    }
}

@media screen and (max-width: 600px) {
    .error-container-mobile span {
        font-size: 55px;
    }

    .error-container-mobile .btn-center .zh-btn {
        width: 30%;
    }

    .zh-disabled {
        display: none;
    }

    .error-container-mobile .sub-headings {
        font-size: 22px;
        top: 95px;
    }

    .error-container-mobile .zh-size {
        font-size: 40px;
    }

    .error-container-mobile .zh-center {
        top: 105px;
    }

    .error-container-mobile .btn-center .zh-btn a {
        font-size: 20px;
    }

    .error-container-mobile .btn-center .zh-btn {
        width: 46%;
        margin-top: 200px;
        padding: 10px;
    }

    .error-container-mobile .btn-center .btn-gotohome {
        margin-top: 160px;
        padding: 10px;
        width: 53%;
    }

    .error-container-mobile .btn-center .btn-gotohome a {
        font-size: 24px;
    }

    .error-container-mobile .center {
        top: 180px;
    }
}

@media screen and (max-width: 560px) {
    .error-container-mobile .zh-size {
        font-size: 35px;
    }

    .error-container-mobile .btn-center .zh-btn {
        width: 42%;
        margin-top: 189px;
        padding: 10px;
    }

    .error-container-mobile span {
        font-size: 45px;
    }

    .error-container-mobile .btn-center .zh-btn a {
        font-size: 17px;
    }

    .error-container-mobile .sub-headings {
        font-size: 18px;
        top: 80px;
    }

    .error-container-mobile .btn-center .btn-gotohome a {
        font-size: 20px;
    }

    .error-container-mobile .btn-center .btn-gotohome {
        width: 45%;
        margin-top: 138px;
    }
}

@media screen and (max-width: 500px) {
    .error-container-mobile span {
        font-size: 40px;
    }

    .error-container-mobile .center {
        top: 170px;
    }

    .error-container-mobile .sub-headings {
        font-size: 16px;
        top: 69px;
    }

    .error-container-mobile .btn-center .btn-gotohome a {
        font-size: 18px;
    }

    .error-container-mobile .btn-center .btn-gotohome {
        margin-top: 124px;
    }
}

@media screen and (max-width: 480px) {

    .error-container-mobile .btn-center .zh-btn a {
        font-size: 14px;
    }
}

@media screen and (max-width: 460px) {
    .error-container-mobile .btn-center .btn-gotohome a {
        font-size: 16px;
    }
}

@media screen and (max-width: 400px) {

    .error-container-mobile .btn-center .btn-gotohome {
        width: 50%;
    }

    .error-container-mobile .zh-size {
        font-size: 30px;
    }

    .error-container-mobile .btn-center .zh-btn {
        width: 50%;
        margin-top: 170px;
        padding: 10px;
    }
}

@media screen and (max-width: 380px) {
    .error-container-mobile .btn-center .btn-gotohome {
        width: 55%;
    }
}

@media screen and (max-width: 360px) {
    .error-container-mobile span {
        font-size: 38px;
    }

    .error-container-mobile .sub-headings {
        font-size: 15px;
    }

    .error-container-mobile .btn-center .btn-gotohome {
        margin-top: 126px;
    }
}

@media screen and (max-width: 340px) {
    .error-container-mobile span {
        font-size: 32px;
    }

    .error-container-mobile .sub-headings {
        font-size: 13px;
        top: 55px;
    }

    .error-container-mobile .btn-center .btn-gotohome a {
        font-size: 14px;
    }

    .error-container-mobile .btn-center .btn-gotohome {
        margin-top: 110px;
    }
}