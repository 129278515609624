/* reset
------------------------------------*/
html {
  background: #ffffff;
}
body,
div,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
ul {
  padding: 0;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
fieldset,
img {
  border: 0;
}
img {
  vertical-align: top;
}
address,
caption,
em,
strong,
th {
  font-style: normal;
  font-weight: normal;
}
ol,
ul {
  list-style: none;
}
caption,
th,
td {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
sup {
  vertical-align: text-top;
}
sub {
  vertical-align: text-bottom;
}
input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
input,
textarea,
select {
  font-size: 100%;
}
a {
  text-decoration: none;
}
a:hover {
  cursor: pointer;
}
