.gallery-slide {
  width: 100%;
  background: #f5f8fa;

  &-wrap {
    width: 100%;
    max-width: 1830px;
    margin: 0 auto;
    padding: 70px 0;

    .slick-next:before {
      background-image: url("/assets/images/arrows/right.svg");
      background-repeat: no-repeat;
      background-size: contain;
      color: transparent !important;
      font-size: 60px !important;
    }

    .slick-prev:before {
      background-image: url("/assets/images/arrows/left.svg");
      background-repeat: no-repeat;
      background-size: contain;
      color: transparent !important;
      font-size: 60px !important;
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
      width: 92%;
      left: 30px;
    }

    .slick-next {
      right: 100px !important;
    }

    .slick-prev {
      left: 45px !important;
      z-index: 4;
    }
    .slick-prev, .slick-next {
      top: 41% !important;
    }
    .slick-initialized .slick-slide {
      left: 16px;
      position: relative;
    }

    .inlines {
      display: flex !important;
      justify-content: center;
      width: 96% !important;

      .event-name {
        width: 51%;
      }

      .inline-image {
        position: relative;
        justify-content: center;
        align-items: center;
        width: 95%;
        height: 600px;

        .react-player__preview {
          height: 600px !important;
          width: 95% !important;
          left: 16px;
          position: relative;
        }

        .tg-image {
          width: 100%;
          height: 465px;
          position: absolute;
          object-fit: contain;
        }

        video {
          width: 85% !important;
          top: 36px;
          height: 550px !important;
          object-fit: cover !important;
          border-radius: 20px;
          left: 70px;
          position: relative;
        }

        .play-btn {
          width: 180px;
          float: center;
          height: 120px;
          object-fit: contain;
          cursor: pointer;
        }
      }

      .disabled {
        display: none;
      }

      .heading {
        margin-bottom: 135px;
        color: #26325c;
        width: 70%;
        line-height: 1;
        font-size: 56px;
        transform: translate(0px, 80px);
        font-family: SairaBold;
        font-weight: bold;
        text-transform: uppercase;
      }

      .sub-heading {
        display: inline-block;
        margin-bottom: 10px;
        padding: 0px 10px;
        font-size: 25px;
        letter-spacing: 1.6px;
        line-height: 2;
        color: #fff;
        font-family: SairaMedium;
        background-color: #f4303f;
        text-transform: uppercase;
      }

      .sub-heading-two {
        display: table;
        margin-bottom: 10px;
        padding: 0px 10px;
        font-size: 25px;
        letter-spacing: 1.6px;
        line-height: 2;
        color: #fff;
        font-family: SairaMedium;
        background-color: #f4303f;
        text-transform: uppercase;
      }

      .photo-link {
        top: 15%;
        position: relative;
        text-transform: uppercase;
        text-decoration: underline;
        color: #28345d;
        cursor: pointer;
      }
    }

    .inline:first-child {
      width: 10%;
    }

    .inline:last-child {
      width: 10%;
    }
  }
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  padding-left: 0px !important;
  overflow-y: auto;
  padding: 0;
  background-color: rgb(0 0 0 / 59%) !important;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;

  .modal-dialog {
    position: relative;
    width: auto;
    margin: 8px;
    pointer-events: none;
  }

  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
  }

  @media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      transition: none;
    }
  }

  .modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
  }

  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }

  .modal-dialog-scrollable {
    display: -ms-flexbox;
    display: flex;
    max-height: calc(100% - 16px);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 16px);
    overflow: hidden;
  }

  .modal-dialog-scrollable .modal-footer,
  .modal-dialog-scrollable .modal-header {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
  }

  .modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 16px);
  }

  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 16px);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: "";
  }

  .modal-dialog-centered.modal-dialog-scrollable {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
  }

  .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
  }

  .modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    max-width: 980px;
    pointer-events: auto;
    outline: 0;

    .modal-header {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: start;
      align-items: flex-start;
      -ms-flex-pack: justify;
      justify-content: space-between;

      .modal-title {
        margin-bottom: 0;
        line-height: 1.5;
      }

      .btn-close {
        background-image: url("/assets/images/arrows/close.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-color: transparent;
        width: 1.5%;
        cursor: pointer;
        border: transparent;
        height: 20px;
        transform: translate(-33px, 10px);
      }

      .close {
        padding: 16px 16px;
        margin: -16px -16px -16px auto;
      }
    }

    .btn-header {
      justify-content: space-around;
    }
  }

  .modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 16px;

    img {
      width: 100%;
      transition: all 0.2s ease-in-out;
      margin-bottom: 10px;

      &:hover {
        transform: scale(1.1);
      }
    }

    img:last-child {
      height: 570px;
      object-fit: contain;
    }

    .slick-prev,
    .slick-next {
      width: 30px;
      height: 30px;
      left: 50%;
      right: unset;
      bottom: -30px;
      top: unset;
      z-index: 10;
    }

    .slick-next {
      transform: translateX(10px);
    }

    .slick-prev {
      transform: translateX(-40px);
    }

    .slick-next:before {
      background-image: url("/assets/images/arrows/arrow-right-modal.png");
      background-repeat: no-repeat;
      background-size: contain;
      color: transparent !important;
      font-size: 28px !important;
    }

    .slick-prev:before {
      background-image: url("/assets/images/arrows/arrow-left-modal.png");
      background-repeat: no-repeat;
      background-size: contain;
      color: transparent !important;
      font-size: 28px !important;
    }
  }

  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
}
