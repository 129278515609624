.lootbox {
    &-title {
        position: relative;
        background-image: url("/assets/images/desktop/lootbox-bg.jpg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        height: 770px;

        h2 {
            position: relative;
            top: 90px;
            left: 0;
            right: 0;
            margin: auto;
            font-family: SairaBold;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            font-weight: bold;

            span {
                display: block;

                &.sm {
                    font-size: 19px;
                }

                &.lg {
                    font-size: 58px;
                }
            }
        }

        img {
            width: 100%;
        }
    }

    &-features {
        position: relative;
        display: flex;
        justify-content: center;
        align-content: center;
        padding: 10% 0 7%;
        background-color: #f5f8fa;

        .features {
            &-device {
                position: absolute;
                top: 17%;
                left: 0;
                right: 0;
                width: 480px;
                margin: auto;

                img {
                    width: 100%;
                }
            }

            &-list {
                margin: 0;
                font-size: 26px;
                color: #26325c;

                li {
                    display: flex;
                    font-weight: 900;
                    align-content: center;
                    max-width: 415px;
                    margin-bottom: 63px;

                    &:before {
                        content: '';
                        display: block;
                        min-width: 88px;
                        min-height: 88px;
                        width: 88px;
                        height: 88px;
                    }
                }

                &.left {
                    margin-right: 15%;

                    li {
                        text-align: left;

                        &:nth-child(1) {
                            &:before {
                                margin-right: 19px;
                                background-image: url('/assets/images/feature-icon-1.png');
                                background-size: 100% 100%;
                            }
                        }

                        &:nth-child(2) {
                            &:before {
                                margin-right: 19px;
                                background-image: url('/assets/images/feature-icon-2.png');
                                background-size: 100% 100%;
                            }
                        }

                        &:nth-child(3) {
                            &:before {
                                margin-right: 19px;
                                background-image: url('/assets/images/feature-icon-3.png');
                                background-size: 100% 100%;
                            }
                        }
                    }
                }

                &.right {
                    margin-left: 15%;

                    li {
                        flex-direction: row-reverse;
                        text-align: right;

                        &:nth-child(1) {
                            &:before {
                                margin-left: 19px;
                                background-image: url('/assets/images/feature-icon-4.png');
                                background-size: 100% 100%;
                            }
                        }

                        &:nth-child(2) {
                            &:before {
                                margin-left: 19px;
                                background-image: url('/assets/images/feature-icon-5.png');
                                background-size: 100% 100%;
                            }
                        }

                        &:nth-child(3) {
                            &:before {
                                margin-left: 19px;
                                background-image: url('/assets/images/feature-icon-6.png');
                                background-size: 100% 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1250px) {
    .lootbox-title h2 span.lg {
        font-size: 50px;
    }

    .lootbox-title {
        height: 660px;
    }

    .lootbox-title h2 {
        top: 70px;
    }

    .lootbox-features .features-device {
        width: 385px;
    }

    .lootbox-features .features-list.left {
        margin-right: 15%;
    }

    .lootbox-features .features-list li {
        max-width: 370px;
        font-size: 22px;
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 1200px) {
    .lootbox-title h2 span.lg {
        font-size: 45px;
    }

    .lootbox-features .features-device {
        width: 380px;
    }

    .lootbox-title {
        height: 600px;
    }

    .lootbox-features .features-list li {
        max-width: 355px;
        font-size: 21px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 1150px) {
    .lootbox-features .features-device {
        width: 360px;
    }

    .lootbox-features .features-list li {
        max-width: 340px;
        font-size: 19px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 1100px) {
    .lootbox-title h2 span.lg {
        font-size: 40px;
    }

    .lootbox-title {
        height: 550px;
    }
}

@media screen and (max-width: 1024px) {
    .lootbox-title h2 span.lg {
        font-size: 35px;
    }

    .lootbox-title {
        height: 505px;
    }

    .lootbox-features .features-device {
        width: 280px;
    }

    .lootbox-features .features-list li:before {
        min-width: 70px;
        min-height: 70px;
        width: 70px;
        height: 70px;
    }

    .lootbox-features .features-list.left {
        margin-right: 10%;
    }

    .lootbox-features .features-list li {
        max-width: 290px;
        font-size: 16px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 900px) {
    .lootbox-title h2 span.lg {
        font-size: 30px;
    }

    .lootbox-title {
        height: 465px;
    }

    .lootbox-features .features-list li {
        max-width: 268px;
        font-size: 14px;
        margin-bottom: 17px;
    }

    .lootbox-features .features-device {
        width: 255px;
    }

    .lootbox-features .features-list li:before {
        min-width: 65px;
        min-height: 65px;
        width: 65px;
        height: 65px;
    }
}

@media screen and (max-width: 800px) {
    .lootbox-title h2 span.lg {
        font-size: 25px;
    }

    .lootbox-features .features-device {
        width: 235px;
    }

    .lootbox-title {
        height: 390px;
    }

    .lootbox-title h2 {
        top: 50px;
    }

    .lootbox-features .features-list li {
        max-width: 250px;
        font-size: 13px;
        margin-bottom: 17px;
    }
}

@media screen and (max-width: 768px) {
    .lootbox-title h2 span.sm {
        font-size: 15px;
    }

    .lootbox-title h2 span.lg {
        font-size: 22px;
    }

    .lootbox-title h2 {
        top: 45px;
    }

    .lootbox-title {
        height: 360px;
    }
}

@media screen and (max-width: 600px) {
    .lootbox-title h2 span.sm {
        font-size: 14px;
    }

    .lootbox-title h2 span.lg {
        font-size: 18px;
    }

    .lootbox-title h2 {
        top: 35px;
    }

    .lootbox-title {
        height: 300px;
    }
}

@media screen and (max-width: 500px) {
    .lootbox-title h2 span.lg {
        font-size: 16px;
    }

    .lootbox-title h2 span.sm {
        font-size: 13px;
    }

    .lootbox-title h2 {
        top: 23px;
    }

    .lootbox-title {
        height: 250px;
    }
}

@media screen and (max-width: 440px) {
    .lootbox-title h2 span.sm {
        font-size: 11px;
    }

    .lootbox-title h2 span.lg {
        font-size: 14px;
    }

    .lootbox-title {
        height: 220px;
    }
}

@media screen and (max-width: 380px) {
    .lootbox-title h2 span.lg {
        font-size: 12px;
    }

    .lootbox-title {
        height: 200px;
    }

    .lootbox-title h2 span.sm {
        font-size: 10px;
    }
}

@media screen and (max-width: 340px) {
    .lootbox-title h2 {
        top: 15px;
    }

    .lootbox-title {
        height: 165px;
    }

    .lootbox-title h2 span.sm {
        font-size: 6px;
    }
}