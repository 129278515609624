:root {
    --size: clamp(160px, 16px + 40vmin, 480px);
    --gap: calc(var(--size) / 14);
    --duration: 50s;
    --scroll-start: 0;
    --scroll-end: calc(-100% - var(--gap));
}

.gallery {
    width: 100%;
    background-color: #fff;

    &-wrap {
        display: flex;
        width: 100%;
        margin: auto;
        justify-content: flex-end;
    }

    .details {
        width: 30%;
    }

    .gl-heading {
        padding-top: 70px;
        padding-left: 90px;
        position: relative;

        .heading {
            margin-bottom: 14px;
            width: 60%;
            color: #26325c;
            font-size: 56px;
            font-family: SairaBold;
            font-weight: bold;
            text-transform: uppercase;
        }

        .zh-heading {
            margin-bottom: 14px;
            width: 70%;
            color: #26325c;
            font-size: 56px;
            font-family: SairaBold;
            font-weight: bold;
            text-transform: uppercase;
        }

        .bottom-img {
            position: absolute;
            bottom: 0;

            .Tpaimage-en {
                width: 60%;
                object-fit: contain;
            }

            .Tpaimage {
                width: 70%;
                object-fit: contain;
            }
        }


        .sub-heading {
            display: inline-block;
            margin-bottom: 10px;
            padding: 0px 10px;
            font-size: 25px;
            letter-spacing: 1.6px;
            line-height: 2;
            color: #fff;
            font-family: SairaMedium;
            background-color: #f4303f;
            text-transform: uppercase;
        }

        .sub-heading-two {
            display: flex;
            margin-bottom: 10px;
            padding: 0px 10px;
            display: table;
            font-size: 25px;
            letter-spacing: 1.6px;
            line-height: 2;
            color: #fff;
            font-family: SairaMedium;
            background-color: #f4303f;
            text-transform: uppercase;
        }

        .zh_disabled {
            display: none
        }
    }

    &-contents {
        overflow: hidden;
        width: 100%;
        display: flex;
        box-sizing: border-box;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;

        .gallery-block {
            flex-direction: row;
            width: 100%;
            height: 710px;
            list-style: none;

            .top:hover .down:hover {
                animation-play-state: paused;
            }

            .top {
                flex-shrink: 0;
                display: inline-block;
                align-items: center;
                justify-content: space-around;
                gap: var(--gap);
                animation-duration: 10s;
                animation: scroll-top var(--duration) linear infinite;
                animation-iteration-count: infinite;
            }

            .down {
                flex-shrink: 0;
                display: inline-block;
                align-items: center;
                justify-content: space-around;
                gap: var(--gap);
                animation-duration: 10s;
                animation: scroll-down var(--duration) linear infinite;
                animation-iteration-count: infinite;
            }

            .thumb {
                width: 100%;
                overflow: revert;

                img {
                    transition: all .2s ease-in-out;
                    width: 97%;
                    object-fit: contain;
                    padding-left: 10px;
                    white-space: nowrap;
                    display: flex;
                    flex-shrink: 0;

                    &:hover {
                        transform: scale(1.05);
                    }
                }

                .lol-image {
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}


// image animation
@keyframes scroll-top {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(-410.5%);
    }
}

@keyframes scroll-down {
    0% {
        transform: translateY(-410.5%);
    }

    100% {
        transform: translateY(0%);
    }
}