.contact-box {
  width: 100%;
  background-color: #f5f8fa;
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  transition: .5s ease;

  &-wrap {
    display: flex;
    width: 100%;
    margin: auto;

    &-left {
      display: inherit;
      float: left;
      position: relative;
      width: 45%;
      background-image: url("/assets/images/desktop/contact/bg-image.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%;

      .inside-image {
        object-fit: contain;
        top: 40px;
        position: relative;
        display: block;
        width: 100%;
      }

      .div-inside-image {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        max-width: 1040px;
        margin: auto;

        .social_media {
          text-align: center;
          width: 100%;
          max-width: 900px;
          color: white;
        }

        h1 {
          line-height: 1.25;
          width: 100%;
          max-width: 201px;
          margin: auto;
          position: relative;
          color: white;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          letter-spacing: normal;
          text-transform: uppercase;
          font-stretch: normal;
          font-style: normal;
        }

        .contacts {
          display: flex;
          flex-basis: 50%;

          ul {
            list-style: none;
            display: flex;
            width: 100%;
            justify-content: center;

            li {
              display: block;

              a {
                color: #fff;
                font-size: 19px;
                padding-left: 8px;
                display: inline-block;
                vertical-align: middle;
                text-decoration: none;
              }

              img {
                width: 100%;
                object-fit: contain;
                display: inline-block;
                max-width: 80px;
                vertical-align: middle;
              }
            }
          }
        }

        .site_number {
          font-size: 22px;
          margin-bottom: 60px;
        }

        .message {
          width: 100%;
          height: 45px;
          object-fit: contain;
          margin-right: 10px;
          cursor: pointer;
        }

        .call {
          width: 100%;
          height: 45px;
          object-fit: contain;
          cursor: pointer;
        }

        .phone-num {
          width: 100%;
          margin: auto;
          max-width: 216px;
          transform: translate(10px, 79px);
          color: white;
          font-size: 23px;
        }
      }
    }

    &-right {
      float: right;
      width: 40%;
      padding-top: 70px;
      position: relative;
      margin: auto;

      .heading {
        margin-bottom: 10px;
        color: #26325c;
        font-size: 54px;
        text-align: left;
        font-family: SairaBold;
        font-weight: bold;
        text-transform: uppercase;
      }

      .div_succes {
        text-align: center;

        h2 {
          text-align: center;
        }

        .success_btn {
          margin-top: 20px;
          cursor: pointer;
          border: 2px solid red;
          padding: 10px;
          width: 210px;
          color: red;
          font-weight: 900;
          text-transform: uppercase;
          font-size: 20px;
          transition: 0.1s;
        }

        .success_btn:hover {
          box-shadow: 1000px 0 0 0 #f4303f inset;
          color: #fff;
        }
      }

      form {
        display: table;
        margin: auto;
        max-width: 700px;
        width: 100%;

        .code {
          background: transparent;
          text-align: left;
          position: absolute;
          left: 35px;
          top: 0;
          transform: translate(10px, 8px);
          width: 42px;
          color: #a3b4c7;
          line-height: 2;
          padding: 0;
          font-size: 12px;
        }

        small {
          color: red;
          font-size: 11px;
        }

        .row:after {
          content: "";
          display: table;
          clear: both;
        }

        input {
          padding: 10px;
          width: calc(100% - 20px);
          border-radius: 4px;
          border: transparent;
          margin-bottom: 10px;
          outline: none;
          color: #26325c;
          font-size: 12px;
          resize: vertical;
          width: 100%;
        }

        textarea {
          resize: none;
          border-radius: 4px;
          width: 100%;
          padding: 10px;
          font-size: 12px;
          height: 140px;
          border: none;
          margin-bottom: 12px;
          font-size: 12px;
          color: #26325c;
          text-transform: capitalize;
        }

        .btn-div {
          width: 100%;
          margin: auto;
          max-width: 170px;
          margin-bottom: 30px;

        }

        button {
          border: 2px solid red;
          padding: 10px;
          width: 100%;
          background: white;
          font-family: SairaBold;
          text-transform: uppercase;
          font-weight: 900;
          font-size: 14px;
          color: red;
          cursor: pointer;
          transition: 0.1s;

          a {
            text-transform: uppercase;
            font-family: SairaBold;
            font-weight: 900;
            font-size: 14px;
            color: red;
          }
        }

        button:hover {
          box-shadow: 1000px 0 0 0 #f4303f inset;
          color: #fff;
        }

        textarea:focus {
          outline: none !important;
          border: 1px solid #26325c;
        }

        .input:focus {
          outline: none !important;
          border: 1px solid red;
          box-shadow: 0 0 10px #719ece;
        }

        input[type="text"]:focus {
          border: 1px solid #26325c;
        }

        .invalid {
          border: 1px solid red;
        }

        .invalid:focus {
          border: 1px solid red;
        }

        ::placeholder {
          color: #9caec3;
          opacity: 1;
          font-size: 12px;
        }

        .details {
          width: 100%;
          margin: auto;
          max-width: 700px;

          .contact-details {
            text-transform: none;
            font-size: 11px;
          }

          span {
            width: 100%;
            height: 14px;
            font-family: Saira;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.78;
            letter-spacing: normal;
            text-align: left;
            color: #9caec3;
            text-transform: capitalize;
          }

          .red {
            color: red;
          }

          .form-phone {
            background-color: #f0f2f7;
            border-radius: 4px;
            height: 125px;
            margin-bottom: 10px;
            resize: vertical;
            padding: 15px;
            width: 98%;

            label {
              font-size: 12px;
              color: #9caec3;
              text-transform: capitalize;
            }

            .phone {
              transform: translate(0px, 5px);

              .container {
                display: flex;

                .inline {
                  display: inline-block;

                  .PhoneInput {
                    .PhoneInputCountry {
                      background-color: white;
                      width: 40px;
                      height: 20px;
                      padding: 10px;
                      border-radius: 4px;
                    }
                  }

                  .PhoneInputInput {
                    flex: none !important;
                    min-width: 0 !important;
                    width: 88% !important;
                    left: 5px;
                  }

                  .react-tel-input {
                    display: inline !important;
                    font-family: Saira !important;
                    color: #9caec3;

                    .form-control {
                      max-width: 900px;
                      padding-left: 10px !important;
                      border: transparent !important;
                      color: #26325c !important;
                      opacity: 1 !important;
                      font-size: 12px !important;
                      height: 39px !important;
                      resize: vertical !important;
                      width: 81% !important;
                      margin-bottom: 12px !important;
                      left: 121px;
                    }

                    .flag-dropdown {
                      background-color: white !important;
                      border: transparent !important;
                      padding: 5px !important;
                      height: 37px !important;
                      top: -6px !important;
                    }

                    .selected-flag .arrow {
                      left: 79px !important;
                    }

                    .selected-flag {
                      width: 98px !important;
                    }

                    .flag-dropdown.open .selected-flag {
                      background: transparent !important;
                      border-radius: 3px 0 0 0;
                    }
                  }

                  .react-tel-input .react-tel-input .selected-flag:hover,
                  .react-tel-input .selected-flag:focus {
                    background: transparent !important;
                  }
                }

                .inline:first-child {
                  width: 21%;
                }

                .inline:last-child {
                  width: 100%;
                }
              }

              .country-code {
                width: 60px;
                margin-right: 10px;
              }

              .number {
                width: 94%;
                margin-left: 10px;
                resize: vertical;
              }
            }

            .skypeTelegram {
              display: flex;

              .width-size {
                display: inline-block;
              }

              .width-size:first-child {
                width: 80%;
                margin-right: 10px;
              }

              .width-size:last-child {
                width: 80%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {

  .col-25,
  .col-75,
  input[type="text"] {
    width: 100%;
    margin-top: 0;
  }
}
