.marquee-box {
    width: 100%;
    background-color: #242a3c;

    .marquee {
        display: inline !important;
        min-width: 0% !important;

        &-image {
            display: flex;
            padding: 10px;
            white-space: nowrap;
            will-change: transform;

            .group {
                max-width: 200px;
                max-height: 60px;
                width: 150px;
                object-fit: contain;
                height: auto;
            }
        }
    }

    .overlay::before,
    .overlay::after {
        background: transparent !important;
    }
}
