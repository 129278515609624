.exclusive {
  width: 100%;
  height: 500px;
  padding-top: 64px;
  background-color: #fff;

  &-wrap {
    display: flex;
    flex: auto;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
  }

  &-title {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-left: 70px;
    flex: max(400px, 100%/4 + 0.1%);

    .details {
      width: 90%;

      .heading {
        margin-bottom: 7px;
      }

      .desc {
        font-size: 13px;
        font-family: SairaMedium;
        color: #26325c;
        text-align: justify;
        line-height: 2;

        span {
          font-weight: bold;
        }
      }

      .buttons {
        width: 95%;
        height: 64px;
        margin-top: 13px;
        outline: none;
        border: 2px solid #f4303f;
        font-weight: 900;
        background-color: transparent;
        font-family: SairaBold;
        font-size: 26px;
        color: #f4303f;
        text-transform: uppercase;
        cursor: pointer;
        transition: 0.1s;
      }

      .buttons:hover {
        box-shadow: 1000px 0 0 0 #f4303f inset;
        color: #fff;
      }
    }
  }

  &-contents {
    overflow: visible;
    flex: max(400px, 100%/4 + 0.1%);
    width: calc(100% - 515px);
    padding-left: 60px;
    box-sizing: border-box;

    .slick-list {
      overflow: hidden;
      height: 380px;

      .slick-slide {
        padding: 0 5px;
        box-sizing: border-box;
      }
    }

    .slick-next {
      right: 640px;
      z-index: 8;
      width: 60px;
      height: 60px;
    }

    .slick-prev {
      left: 600px;
      z-index: 8;
      width: 60px;
      height: 60px;
    }

    .slick-next:before {
      background-image: url("/assets/images/arrows/right.svg");
      background-repeat: no-repeat;
      background-size: contain;
      color: transparent !important;
      font-size: 60px !important;
    }

    .slick-prev:before {
      background-image: url("/assets/images/arrows/left.svg");
      background-repeat: no-repeat;
      background-size: contain;
      color: transparent !important;
      font-size: 60px !important;
    }

    .exclusive-block {
      max-width: 256px;
      width: 100%;
      height: 350px;
      transition: all 0.2s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }

      .thumb {
        width: 100%;
        overflow: hidden;

        img {
          width: 100%;
        }

        .new-game {
          position: absolute;
          transform: translate(10px, 38px);
          background-image: linear-gradient(#afff5f, #f2f236);
          padding: 5px;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          font-weight: 900;
          text-transform: uppercase;
        }
      }

      .desc {
        min-height: 160px;
        padding: 19px;
        box-sizing: border-box;
        box-shadow: 0 1.5px 15px 0 rgba(152, 161, 190, 0.2);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        p {
          font-size: 13px;
          color: #616784;
        }
      }
    }
  }
}

.exclusive-zh {
  width: 100%;
  height: 500px;
  padding-top: 64px;
  background-color: #fff;

  &-wrap {
    display: flex;
    flex: auto;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
  }

  &-title {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-left: 70px;
    flex: max(400px, 100%/4 + 0.1%);

    .details {
      width: 90%;

      .heading {
        margin-bottom: 7px;
      }

      .desc {
        font-size: 13px;
        font-family: SairaMedium;
        color: #26325c;
        text-align: justify;
        line-height: 2;

        span {
          font-weight: bold;
        }
      }

      .buttons {
        width: 95%;
        height: 64px;
        margin-top: 13px;
        outline: none;
        border: 2px solid #f4303f;
        font-weight: 900;
        background-color: transparent;
        font-family: SairaBold;
        font-size: 26px;
        color: #f4303f;
        text-transform: uppercase;
        cursor: pointer;
        transition: 0.1s;
      }

      .buttons:hover {
        box-shadow: 1000px 0 0 0 #f4303f inset;
        color: #fff;
      }
    }
  }

  &-contents {
    overflow: visible;
    flex: max(400px, 100%/4 + 0.1%);
    width: calc(100% - 515px);
    padding-left: 60px;
    box-sizing: border-box;

    .slick-list {
      overflow: hidden;
      height: 380px;

      .slick-slide {
        padding: 0 5px;
        box-sizing: border-box;
      }
    }

    .slick-next {
      right: 640px;
      z-index: 8;
      width: 60px;
      height: 60px;
    }

    .slick-prev {
      left: 600px;
      z-index: 8;
      width: 60px;
      height: 60px;
    }

    .slick-next:before {
      background-image: url("/assets/images/arrows/right.svg");
      background-repeat: no-repeat;
      background-size: contain;
      color: transparent !important;
      font-size: 60px !important;
    }

    .slick-prev:before {
      background-image: url("/assets/images/arrows/left.svg");
      background-repeat: no-repeat;
      background-size: contain;
      color: transparent !important;
      font-size: 60px !important;
    }

    .exclusive-block {
      max-width: 256px;
      width: 100%;
      height: 350px;
      transition: all 0.2s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }

      .thumb {
        width: 100%;
        overflow: hidden;

        img {
          width: 100%;
        }

        .new-game {
          position: absolute;
          transform: translate(10px, 38px);
          background-image: linear-gradient(#afff5f, #f2f236);
          padding: 5px;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          font-weight: 900;
          text-transform: uppercase;
        }
      }

      .desc {
        min-height: 160px;
        padding: 19px;
        box-sizing: border-box;
        box-shadow: 0 1.5px 15px 0 rgba(152, 161, 190, 0.2);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        p {
          font-size: 13px;
          color: #616784;
        }
      }
    }
  }
}

// Desktop Responsive
.exclusive-contents .slick-prev {
  left: 50%;
  transform: translateX(-70px);
  bottom: -40px;
  top: unset;
}

.exclusive-contents .slick-next {
  left: 50%;
  transform: translateX(10px);
  bottom: -40px;
  top: unset;
}

@media screen and (max-width: 1800px) {
  .exclusive-contents .exclusive-block .desc p {
    font-size: 12px;
  }
}

@media screen and (max-width: 1770px) {
  .exclusive-contents .exclusive-block .desc {
    min-height: 172px;
  }
}

@media screen and (max-width: 1700px) {
  .exclusive-contents .exclusive-block .thumb .new-game {
    transform: translate(10px, 32px);
  }
}

@media screen and (max-width: 1600px) {
  .exclusive-contents .exclusive-block .thumb .new-game {
    transform: translate(10px, 29px);
  }

  .exclusive-contents .exclusive-block .desc p {
    font-size: 11px;
  }
}

@media screen and (max-width: 1536px) {
  .exclusive-contents .exclusive-block .thumb .new-game {
    transform: translate(10px, 34px);
  }
}

@media screen and (max-width: 1500px) {
  .exclusive-contents {
    width: calc(100% - 415px);
  }

  .exclusive-contents .exclusive-block .thumb .new-game {
    transform: translate(10px, 38px);
  }
}

@media screen and (max-width: 1440px) {
  .exclusive-contents {
    width: calc(100% - 360px);
  }
}

@media screen and (max-width: 1400px) {
  .exclusive-contents .exclusive-block .thumb .new-game {
    transform: translate(10px, 35px);
  }
}

@media screen and (max-width: 1350px) {
  .exclusive-contents .exclusive-block .desc p {
    font-size: 12px;
  }

  .exclusive-contents .exclusive-block .thumb .new-game {
    position: absolute;
    transform: translate(10px, 35px);
  }
}

@media screen and (max-width: 1300px) {
  .exclusive-contents .exclusive-block .thumb .new-game {
    transform: translate(10px, 31px);
  }
}

@media screen and (max-width: 1256px) {
  .exclusive-contents .exclusive-block .thumb .new-game {
    transform: translate(10px, 30px);
  }
}

@media screen and (max-width: 1250px) {
  .exclusive-contents {
    width: calc(100% - 440px);
  }

  .g-heading .sub-heading {
    font-size: 23px;
  }

  .exclusive-contents .exclusive-block .thumb .new-game {
    position: absolute;
    transform: translate(10px, 27px);
  }
}

@media screen and (max-width: 1200px) {
  .exclusive-contents .exclusive-block .desc p {
    font-size: 11px;
  }

  .exclusive-contents .exclusive-block .thumb .new-game {
    position: absolute;
    transform: translate(10px, 24px);
  }
}

@media screen and (max-width: 1150px) {
  .exclusive-contents .exclusive-block .thumb .new-game {
    position: absolute;
    transform: translate(10px, 27px);
  }

  .exclusive-contents {
    width: calc(100% - 317px);
  }

  .exclusive-title .details .desc {
    font-size: 12px;
  }

  .exclusive-title .details button {
    width: 90%;
    height: 60px;
  }

  .g-heading .sub-heading {
    font-size: 20px;
  }

  .g-heading .heading {
    font-size: 54px;
  }
}

@media screen and (max-width: 1040px) {
  .exclusive-contents .exclusive-block .desc p {
    font-size: 10px;
  }

  .exclusive-contents .exclusive-block .thumb .new-game {
    font-size: 11px;
    transform: translate(10px, 24px);
  }

  .exclusive-contents .exclusive-block .desc {
    min-height: 162px;
  }

  .exclusive-title .details .desc {
    font-size: 11px;
  }

  .exclusive-title .details button {
    width: 86%;
    height: 50px;
    font-size: 24px;
  }
}

@media screen and (max-width: 1080px) {
  .exclusive-contents .exclusive-block .thumb .new-game {
    transform: translate(10px, 24px);
  }
}

@media screen and (max-width: 1040px) {
  .exclusive-contents .exclusive-block .thumb .new-game {
    transform: translate(10px, 23px);
  }
}

@media screen and (max-width: 1024px) {
  .exclusive-contents .exclusive-block .desc p {
    font-size: 12px;
  }

  .exclusive-title .details .desc {
    font-size: 12px;
  }

  .exclusive-contents .exclusive-block .thumb .new-game {
    font-size: 12px;
    transform: translate(10px, 30px);
  }

  .exclusive {
    height: 460px;
  }

  .exclusive-contents .exclusive-block .desc {
    min-height: 170px;
  }
}

@media screen and (max-width: 1000px) {
  .exclusive-contents .exclusive-block .thumb .new-game {
    transform: translate(10px, 29px);
  }
}

@media screen and (max-width: 980px) {
  .exclusive-contents .exclusive-block .desc p {
    font-size: 11px;
  }

  .exclusive-contents .exclusive-block .thumb .new-game {
    transform: translate(10px, 28px);
  }
}

@media screen and (max-width: 950px) {
  .exclusive-contents .exclusive-block .thumb .new-game {
    transform: translate(10px, 26px);
  }
}

@media screen and (max-width: 900px) {
  .exclusive-contents .exclusive-block .thumb .new-game {
    transform: translate(10px, 24px);
  }
}

@media screen and (max-width: 850px) {
  .exclusive-contents .exclusive-block .desc p {
    font-size: 12px;
  }

  .exclusive-contents .exclusive-block .thumb .new-game {
    transform: translate(10px, 33px);
  }
}

@media screen and (max-width: 800px) {
  .exclusive-wrap {
    max-width: 100%;
  }

  .exclusive-contents .exclusive-block .thumb .new-game {
    transform: translate(10px, 29px);
  }
}

@media screen and (max-width: 760px) {

  .exclusive-contents .exclusive-block .thumb .new-game {
    transform: translate(10px, 26.5px);
  }
}