// responsive
@media screen and (max-width: 1745px) {
    @keyframes scroll-top {
        0% {
            transform: translateY(0%);
        }

        100% {
            transform: translateY(-411%);
        }
    }

    @keyframes scroll-down {
        0% {
            transform: translateY(-411%);
        }

        100% {
            transform: translateY(0%);
        }
    }
}

@media screen and (max-width: 1600px) {
    .gallery .gl-heading {
        padding-top: 80px;
    }
}

@media screen and (max-width: 1536px) {
    .gallery-contents .gallery-block {
        height: 700px;
    }

    .gallery .gl-heading .bottom-img .Tpaimage-en {
        width: 70%;
    }

    .gallery .gl-heading .bottom-img .Tpaimage {
        width: 85%;
        transform: translateX(-10px);
    }

    @keyframes scroll-top {
        0% {
            transform: translateY(0%);
        }

        100% {
            transform: translateY(-412%);
        }
    }

    @keyframes scroll-down {
        0% {
            transform: translateY(-412%);
        }

        100% {
            transform: translateY(0%);
        }
    }
}

@media screen and (max-width: 1500px) {
    .gallery .gl-heading .heading {
        margin-bottom: 23px;
    }
}

@media screen and (max-width: 1475px) {
    .gallery .gl-heading .zh-heading {
        width: 80%;
    }
}

@media screen and (max-width: 1440px) {
    .gallery-contents .gallery-block {
        height: 692px;
    }

    .gallery .gl-heading .zh-heading {
        margin-bottom: 0px;
        width: 80%;
    }

}

@media screen and (max-width: 1400px) {
    .gallery-contents {
        width: 90%;
    }
}

@media screen and (max-width: 1300px) {
    .gallery .gl-heading .heading {
        font-size: 50px;
    }

    .gallery-contents .gallery-block {
        height: 660px;
    }

    .gallery .gl-heading .sub-heading-two {
        font-size: 23px;
    }

    .gallery .gl-heading .sub-heading {
        font-size: 23px;
    }
}

@media screen and (max-width: 1280px) {
    .gallery-contents .gallery-block {
        height: 652px;
    }

    @keyframes scroll-top {
        0% {
            transform: translateY(0%);
        }

        100% {
            transform: translateY(-416.6%);
        }
    }

    @keyframes scroll-down {
        0% {
            transform: translateY(-416.6%);
        }

        100% {
            transform: translateY(0%);
        }
    }
}

@media screen and (max-width: 1250px) {
    .gallery-contents .gallery-block {
        height: 650px;
    }
}

@media screen and (max-width: 1200px) {
    .gallery-contents .gallery-block .thumb img {
        width: 100%;
        height: 160px;
    }

    .gallery-contents .gallery-block {
        height: 613px;
    }

    .gallery .gl-heading .heading {
        font-size: 45px;
    }

    .gallery .gl-heading .zh-heading {
        font-size: 45px;
    }

    .gallery .gl-heading .sub-heading-two {
        font-size: 21px;
    }

    .gallery .gl-heading .bottom-img .Tpaimage {
        width: 90%;
    }

    .gallery .gl-heading .sub-heading {
        font-size: 21px;
    }
}

@media screen and (max-width: 1150px) {
    .gallery-contents .gallery-block .thumb img {
        height: 154px;
    }

    .gallery-contents .gallery-block {
        height: 600px;
    }
}

@media screen and (max-width: 1100px) {
    .gallery-contents .gallery-block .thumb img {
        height: 146px;
    }

    .gallery-contents .gallery-block {
        height: 590px;
    }
}

@media screen and (max-width: 1080px) {
    .gallery-contents .gallery-block {
        height: 579px;
    }

    .gallery-contents .gallery-block .thumb img {
        height: 134px;
    }

    .gallery-contents {
        width: 80%;
    }

    @keyframes scroll-top {
        0% {
            transform: translateY(0%);
        }

        100% {
            transform: translateY(-422%);
        }
    }

    @keyframes scroll-down {
        0% {
            transform: translateY(-422%);
        }

        100% {
            transform: translateY(0%);
        }
    }
}

@media screen and (max-width: 1024px) {
    .gallery .gl-heading .sub-heading {
        font-size: 20px;
    }

    .gallery .gl-heading .sub-heading-two {
        font-size: 20px;
    }

    .gallery-contents .gallery-block .thumb img {
        height: 124px;
    }

    .gallery .details {
        width: 33%;
    }

    .gallery-contents .gallery-block {
        height: 588px;
    }

    @keyframes scroll-top {
        0% {
            transform: translateY(0%);
        }

        100% {
            transform: translateY(-423.5%);
        }
    }

    @keyframes scroll-down {
        0% {
            transform: translateY(-423.5%);
        }

        100% {
            transform: translateY(0%);
        }
    }
}

@media screen and (max-width: 1000px) {
    .gallery-contents .gallery-block {
        height: 578px;
    }

    .gallery-contents .gallery-block .thumb img {
        height: 120px;
    }
}

@media screen and (max-width: 950px) {
    .gallery .gl-heading .heading {
        font-size: 40px;
    }

    .gallery .gl-heading .zh-heading {
        font-size: 40px;
    }


    .gallery .gl-heading .sub-heading {
        font-size: 17px;
    }

    .gallery .gl-heading .sub-heading-two {
        font-size: 17px;
    }

    .gallery-contents .gallery-block .thumb img {
        height: 114px;
    }

    .gallery-contents .gallery-block {
        height: 537px;
    }

    @keyframes scroll-top {
        0% {
            transform: translateY(0%);
        }

        100% {
            transform: translateY(-425.5%);
        }
    }

    @keyframes scroll-down {
        0% {
            transform: translateY(-425.5%);
        }

        100% {
            transform: translateY(0%);
        }
    }
}

@media screen and (max-width: 900px) {
    .gallery-contents .gallery-block .thumb img {
        height: 106px;
    }

    .gallery-contents .gallery-block {
        height: 524px;
    }

    header nav ul li a {
        font-size: 9px;
    }

    @keyframes scroll-top {
        0% {
            transform: translateY(0%);
        }

        100% {
            transform: translateY(-426.6%);
        }
    }

    @keyframes scroll-down {
        0% {
            transform: translateY(-426.6%);
        }

        100% {
            transform: translateY(0%);
        }
    }
}

@media screen and (max-width: 850px) {
    .gallery-contents .gallery-block .thumb img {
        height: 100px;
    }

    .gallery-contents .gallery-block {
        height: 500px;
    }

    .gallery .gl-heading .sub-heading {
        font-size: 14px;
    }

    .gallery .gl-heading .sub-heading-two {
        font-size: 14px;
    }
}

@media screen and (max-width: 800px) {
    .gallery-contents .gallery-block .thumb img {
        height: 92px;
    }

    .gallery .gl-heading {
        padding-top: 50px;
    }

    header nav ul li a {
        font-size: 8px;
    }

    .gallery-contents .gallery-block {
        height: 460px;
    }

    .gallery .gl-heading .Tpaimage {
        width: 75%;
    }
}

@media screen and (max-width: 768px) {
    .gallery-wrap {
        display: table;
        text-align: center;
        padding-top: 0px;
    }

    .gallery .details {
        width: 100%;
    }

    .gallery-contents {
        width: 100%;
    }

    .gallery-contents .gallery-block .thumb img {
        height: 143px;
        padding-left: 0px;
    }

    .gallery .gl-heading {
        padding-left: 8px;
    }

    .gallery-contents .gallery-block {
        height: 500px;
    }

    .gallery .gl-heading .heading {
        font-size: 57px;
        width: 100%
    }

    .gallery .gl-heading .zh_disabled-sub {
        display: inline-block;
        margin-bottom: 8px;
        padding: 0px 45px;
        font-size: 20px;
        letter-spacing: 1.6px;
        line-height: 2;
        color: #fff;
        font-family: SairaMedium;
        background-color: #f4303f;
        text-transform: uppercase;
    }

    .gallery .gl-heading .zh_disabled {
        display: none
    }

    .gallery .gl-heading .zh-heading {
        margin-bottom: 14px;
        width: 100%;
        color: #26325c;
        font-size: 56px;
        font-family: SairaBold;
        font-weight: bold;
        text-transform: uppercase;
    }

    .gallery .gl-heading .sub-heading {
        display: inline-block;
        margin-bottom: 8px;
        padding: 0px 16px;
        font-size: 20px;
        letter-spacing: 1.6px;
        line-height: 2;
        color: #fff;
        font-family: SairaMedium;
        background-color: #f4303f;
        text-transform: uppercase;
    }

    .gallery .gl-heading .sub-heading-two {
        display: inline-block;
        margin-bottom: 8px;
        padding: 0px 18px;
        font-size: 29px;
        letter-spacing: 1.6px;
        line-height: 2;
        color: #fff;
        font-family: SairaMedium;
        background-color: #f4303f;
        text-transform: uppercase;
    }
}

@media screen and (max-width: 740px) {
    .gallery-contents .gallery-block .thumb img {
        height: 139px;
    }

    .gallery-contents {
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .gallery-contents .gallery-block .thumb img {
        height: 130px;
    }
}

@media screen and (max-width: 680px) {
    .gallery-contents .gallery-block .thumb img {
        height: 127px;
    }
}

@media screen and (max-width: 650px) {
    .gallery-contents .gallery-block .thumb img {
        height: 122px;
    }
}

@media screen and (max-width: 600px) {
    .gallery-contents .gallery-block .thumb img {
        height: 111px;
    }
    .gallery .gl-heading .zh-heading {
        font-size: 46px;
    }
    .gallery .gl-heading .sub-heading {
        font-size: 16px;
    }

    .gallery .gl-heading .sub-heading {
        font-size: 16px;
    }

    .gallery .gl-heading .sub-heading-two {
        font-size: 23px;
    }
}

@media screen and (max-width: 580px) {
    .gallery-contents .gallery-block .thumb img {
        height: 108px;
    }
}

@media screen and (max-width: 550px) {
    .gallery-contents .gallery-block .thumb img {
        height: 101px;
    }

    .gallery-contents .gallery-block {
        height: 415px;
    }
}

@media screen and (max-width: 530px) {
    .gallery-contents .gallery-block .thumb img {
        height: 97px;
    }
}

@media screen and (max-width: 500px) {
    .gallery-contents .gallery-block .thumb img {
        height: 92px;
    }

    .gallery .gl-heading {
        padding-left: 0px;
    }

    .gallery .gl-heading .sub-heading {
        font-size: 14px;
    }

    .gallery .gl-heading .heading {
        font-size: 50px;
    }

    .gallery .gl-heading .zh-heading {
        font-size: 42px;
    }

    .gallery .gl-heading .sub-heading {
        font-size: 14px;
    }

    .gallery .gl-heading .sub-heading-two {
        font-size: 20px;
    }

    .gallery_mobile-wrap .details .heading {
        font-size: 40px;
    }
}

@media screen and (max-width: 480px) {
    .gallery .gl-heading .heading {
        font-size: 40px;
    }

    .gallery-contents .gallery-block .thumb img {
        height: 88px;
    }
}

@media screen and (max-width: 460px) {
    .gallery-contents .gallery-block .thumb img {
        height: 84px;
    }

    .gallery-contents .gallery-block {
        height: 400px;
    }
}

@media screen and (max-width: 440px) {
    .gallery-contents .gallery-block .thumb img {
        height: 80px;
    }
    .gallery .gl-heading .heading {
        font-size: 45px;
    }
}

@media screen and (max-width: 400px) {
    .gallery-contents .gallery-block .thumb img {
        height: 72px;
    }

    .gallery_mobile-wrap .details .heading {
        font-size: 35px;
    }

    .gallery-contents .gallery-block {
        height: 370px;
    }

    .gallery-contents .gallery-block .thumb img:hover {
        transform: scale(1.1);
    }

    .gallery .gl-heading .heading {
        font-size: 38px;
    }
}

@media screen and (max-width: 380px) {
    .gallery-contents .gallery-block .thumb img {
        height: 68px;
    }

    .gallery .gl-heading .heading {
        font-size: 38px;
    }

    .gallery .gl-heading .zh-heading {
        font-size: 38px;
    }

    .gallery_mobile-wrap .details .sub-heading {
        font-size: 12px;
    }

    .gallery .gl-heading .sub-heading {
        font-size: 12px;
    }

    .gallery .gl-heading .sub-heading-two {
        font-size: 17px;
    }

}

@media screen and (max-width: 360px) {
    .gallery-contents .gallery-block .thumb img {
        height: 64px;
    }
    .gallery .gl-heading .heading {
        font-size: 34px;
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 340px) {
    .gallery-contents .gallery-block .thumb img {
        height: 59px;
    }
    .gallery .gl-heading .zh-heading {
        font-size: 34px;
    }
    .gallery-contents .gallery-block {
        height: 315px;
    }

    .gallery .gl-heading .heading {
        font-size: 30px;
    }

    .gallery .gl-heading .sub-heading {
        font-size: 10px;
        margin-bottom: 0px;
    }

    .gallery .gl-heading .sub-heading-two {
        font-size: 15px;
    }
}

@media screen and (max-width: 320px) {
    .gallery-contents .gallery-block .thumb img {
        height: 56px;
    }

    .gallery_mobile-wrap .details .heading {
        font-size: 28px;
    }

    .gallery_mobile-wrap .details .sub-heading {
        font-size: 11px;
    }
    .gallery .gl-heading .zh-heading {
        font-size: 33px;
    }
    .gallery_mobile-wrap .details .sub-heading-two {
        font-size: 11px;
        padding: 0px 27px;
    }

    .gallery-contents .gallery-block {
        height: 300px;
    }
}