.section {
    position: relative;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    transition: .5s ease;
}

.about-us {
    display: flex;
    padding: 9% 0;
    background-color: #f5f8fa;

    &-container {
        width: 85%;
        margin: auto;

        .table {
            display: table;

            .about {
                &-img {
                    position: relative;
                    width: 54%;
                    display: table-cell;
                    padding-right: 48px;
                    box-sizing: border-box;

                    .hero-bg {
                        width: 100%;
                        object-fit: contain;
                    }

                    .hero-jump {
                        position: absolute;
                        top: 27%;
                        right: 27%;
                        width: 55%;

                        img {
                            width: 100%;
                            animation: up_down 2s infinite alternate;
                        }
                    }
                }

                &-contents {
                    width: 45%;
                    display: table-cell;
                    text-align: left;
                    max-width: 520px;
                    padding-left: 48px;
                    box-sizing: border-box;

                    .desc {
                        font-size: 19px;
                        font-family: SairaMedium;
                        color: #26325c;
                        text-align: justify;
                        line-height: 2;

                        span {
                            font-weight: bold;
                        }

                        .animate__bottom {
                            transform: translateY(25px);
                            opacity: 0;
                        }

                        .animate__bottom.ease {
                            transition: 1s cubic-bezier(.68, -.55, .27, 2);
                        }

                        .ease {
                            opacity: 1;
                            transform: none;
                            transition: 1s cubic-bezier(.68, -.55, .27, 1.55);
                        }
                    }
                }
            }

        }

        .table.table-full {
            width: 100%;
            height: 100%;
        }

        .table .table-cell:last-child {
            width: 45%;
        }
    }

    @keyframes up_down {
        from {
            transform: translateY(5%)
        }

        to {
            transform: translateY(0)
        }
    }
}

@media screen and (max-width: 1200px) {
    .about-us-container .table .about-contents {
        padding-left: 20px;
    }

    .about-us-container .table .about-contents .desc {
        font-size: 16px;
    }
}

@media screen and (max-width: 1100px) {
    .about-us-container .table .about-contents .desc {
        font-size: 15px;
    }
}

@media screen and (max-width: 1024px) {
    .about-us-container .table .about-contents .desc {
        font-size: 14px;
    }
}

@media screen and (max-width: 900px) {
    .about-us-container .table .about-contents .desc {
        font-size: 13px;
    }
}

@media screen and (max-width: 800px) {
    .about-us-container .table .about-contents {
        padding-left: 0px;
    }

    .about-us-container .table .about-contents .desc {
        font-size: 12px;
    }

    .about-us-container .table .about-img .hero-bg {
        height: 230px;
    }

    .css-bnlwls {
        -webkit-animation-duration: 0ms !important;
        animation-duration: 0ms !important;
        -webkit-animation-delay: 0ms !important;
        animation-delay: 0ms !important;
    }
}