.mobile_lootbox {
    &-features {
        display: table;
        width: 100%;
        justify-content: center;
        align-content: center;
        background-color: #f5f8fa;

        .features {
            width: 90%;
            margin: auto;
            padding-top: 70px;

            &-devices {
                display: table-row;

                img {
                    width: 80%;
                    margin-left: 50px;
                    object-fit: contain;
                }
            }

            &-list {
                margin-bottom: 70px;
                font-size: 24px;
                color: #26325c;

                li {
                    display: flex;
                    font-weight: 900;
                    align-content: center;
                    width: 100%;
                    margin-bottom: 10px;

                    &:before {
                        content: '';
                        display: block;
                        min-width: 88px;
                        min-height: 88px;
                        width: 88px;
                        height: 88px;
                    }
                }

                &.info {
                    li {
                        &:nth-child(1) {
                            &:before {
                                margin-right: 19px;
                                background-image: url('/assets/images/feature-icon-1.png');
                                background-size: 100% 100%;
                            }
                        }

                        &:nth-child(2) {
                            &:before {
                                margin-right: 19px;
                                background-image: url('/assets/images/feature-icon-2.png');
                                background-size: 100% 100%;
                            }
                        }

                        &:nth-child(3) {
                            &:before {
                                margin-right: 19px;
                                background-image: url('/assets/images/feature-icon-3.png');
                                background-size: 100% 100%;
                            }
                        }

                        &:nth-child(4) {
                            &:before {
                                margin-right: 19px;
                                background-image: url('/assets/images/feature-icon-4.png');
                                background-size: 100% 100%;
                            }
                        }

                        &:nth-child(5) {
                            &:before {
                                margin-right: 19px;
                                background-image: url('/assets/images/feature-icon-5.png');
                                background-size: 100% 100%;
                            }
                        }

                        &:nth-child(6) {
                            &:before {
                                margin-right: 19px;
                                background-image: url('/assets/images/feature-icon-6.png');
                                background-size: 100% 100%;
                            }
                        }

                        span {
                            transform: translate(0px, 20px);
                        }

                        .span_disabled {
                            display: none;
                        }
                    }
                }
            }
        }

    }

}

@media screen and (max-width: 660px) {
    .mobile_lootbox-features .features-list {
        font-size: 22px;
    }
}

@media screen and (max-width: 550px) {
    .mobile_lootbox-features .features-list {
        font-size: 20px;
    }
}

@media screen and (max-width: 500px) {
    .mobile_lootbox-features .features-list li:before {
        min-width: 80px;
        min-height: 80px;
        width: 80px;
        height: 80px;
    }

    .mobile_lootbox-features .features-list {
        font-size: 18px;
    }

    .mobile_lootbox-features .features-list.info li:nth-child(1):before {
        margin-right: 5px;
    }

    .mobile_lootbox-features .features-list.info li:nth-child(2):before {
        margin-right: 5px;
    }

    .mobile_lootbox-features .features-list.info li:nth-child(3):before {
        margin-right: 5px;
    }

    .mobile_lootbox-features .features-list.info li:nth-child(4):before {
        margin-right: 5px;
    }

    .mobile_lootbox-features .features-list.info li:nth-child(5):before {
        margin-right: 5px;
    }

    .mobile_lootbox-features .features-list.info li:nth-child(6):before {
        margin-right: 5px;
    }
}

@media screen and (max-width: 440px) {
    .mobile_lootbox-features .features-list {
        font-size: 15px;
    }

    .mobile_lootbox-features .features-list.info li span {
        transform: translate(0px, 25px);
    }
}

@media screen and (max-width: 400px) {
    .mobile_lootbox-features .features-devices img {
        margin-left: 35px;
    }

    .mobile_lootbox-features .features-list li {
        margin-bottom: 5px;
    }

}

@media screen and (max-width: 340px) {
    .mobile_lootbox-features .features-devices img {
        margin-left: 30px;
    }

    .mobile_lootbox-features .features-list {
        font-size: 11px;
    }

    .mobile_lootbox-features .features-list li:before {
        min-width: 70px;
        min-height: 70px;
        width: 70px;
        height: 70px;
    }
}
@media screen and (max-width: 320px){
    .lootbox{
        height: 980px;
    }
}