@media screen and (max-width: 1500px) {
    // page 404
    .error-container button {
        left: 630px;
    }
}
@media screen and (max-width: 1470px){
    .error-container button {
        left: 618px;
    }
}
@media screen and (max-width: 1440px) {
    // page 404
    .error-container button {
        left: 602px;
    }
}
@media screen and (max-width: 1400px) {
    // page 404
    .error-container button {
        left: 582px;
    }
}
@media screen and (max-width: 1360px) {
    // page 404
    .error-container button {
        left: 563px;
    }
}
@media screen and (max-width: 1300px) {
    // page 404
    .error-container button {
        left: 532px;
    }
}
@media screen and (max-width: 1270px) {
    // page 404
    .error-container button {
        left: 515px;
    }
}
@media screen and (max-width: 1250px) {
    // page 404
    .error-container button {
        left: 505px;
    }
}
@media screen and (max-width: 1230px) {
    // page 404
    .error-container button {
        left: 497px;
    }
}
@media screen and (max-width: 1200px) {
    // page 404
    .error-container button {
        left: 485px;
    }
    .error-container img {
        height: 690px;
    }
}
@media screen and (max-width: 1150px) {
    // page 404
    .error-container button {
        left: 460px;
    }
}
@media screen and (max-width: 1100px){
    .error-container button {
        left: 430px;
    }
}
@media screen and (max-width: 1060px){
    .error-container button {
        left: 410px;
    }
}
@media screen and (max-width: 1000px){
    .error-container button {
        left: 382px;
    }
}
@media screen and (max-width: 960px){
    .error-container button {
        left: 365px;
    }
}
@media screen and (max-width: 930px){
    .error-container button {
        left: 372px;
        width: 185px;
        top: 130px;
    }
    .error-container h1 {
        font-size: 50px;
    }
    .error-container h2 {
        font-size: 13px;
        top: 87px;
    }
}
@media screen and (max-width: 900px){
    .error-container button {
        left: 355px;
    }
}
@media screen and (max-width: 880px){
    .error-container button {
        left: 345px;
    }
}
@media screen and (max-width: 850px){
    .error-container button {
        left: 330px;
    }
    .error-container img {
        height: 528px;
    }
}
@media screen and (max-width: 800px){
    .error-container button {
        left: 305px;
    }
}
@media screen and (max-width: 770px){
    .error-container button {
        left: 288px;
    }
}