.content-wrapper {
  display: block;
  padding-top: 64px;
}

@media screen and (max-width: 1200px) {
  .content-wrapper {
    padding-top: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .content-wrapper {
    padding-top: 0px;
  }
}
