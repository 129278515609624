@import './reset.scss';
@import './fonts.scss';
@import './general.scss';


html,
body {
	font-size: 16px;
	font-family: Saira;
	scroll-padding-top: 64px;
	overflow-x: hidden;
}

.clickable {
	cursor: pointer;
}

@media screen and (max-width: 1200px) {
	html,
	body {
		scroll-padding-top: 50px;
	}
}

@media screen and (max-width: 850px){
	html,
	body {
		scroll-padding-top: 40px;
	}
}