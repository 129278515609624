header {
	position: fixed;
	z-index: 3;
	width: 100%;
	height: 64px;
	max-width: 100vw;
	background-color: #fff;
	box-shadow: 0 1.5px 20px 0 rgba(0, 0, 0, 0.16);

  nav {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    margin: auto;

    ul {
      display: flex;
      align-items: center;
      width: 100%;

			li {
				position: relative;
				min-width: 64px;
				margin: 3.2%;
				text-align: center;
				cursor: pointer;

				div a:hover {
					border-bottom: 3px solid #f4303f;
					color: #f4303f;
					padding: 16px 0px;
					cursor: pointer;
				}

				div a.active {
					border-bottom: 3px solid #f4303f;
					color: #f4303f;
					padding: 16px 0px;
					cursor: pointer;
				}

        a {
          text-decoration: none;
          color: #616784;
          font-family: SairaBold;
          font-size: 15px;
          font-weight: bold;
          text-transform: uppercase;
          cursor: pointer;

          &:hover {
            color: #f4303f;
          }
        }

        &.logo-list {
          margin-left: 28px;
        }

			}

			&.nav {
				&-left {
					justify-content: flex-end;

					li {
						margin: 0 4.6%;
					}
				}

				&-right {
					justify-content: flex-start;
					width: 60%;
				}

				&-language {
					width: 40%;
				}
			}
		}

		.product_link {
			margin: 5px 0 0 0 !important;
			height: auto;
			padding: 16px 30px;
			text-decoration: none;
			color: #616784;
			border-bottom: 3px solid #f4304000;
			font-family: SairaBold;
			font-size: 15px;
			font-weight: bold;
			text-transform: uppercase;
			position: relative;
			text-align: center;
			cursor: pointer;

			ul.nav-sub-zh {
				position: absolute;
				left: 0px;
				top: 37.8px;
				display: none;
				width: 120px;
				line-height: 35px;
				box-shadow: 0 1.5px 20px 0 rgba(0, 0, 0, 0.16);
				background-color: #fff;
				opacity: 0;
				transition: 0.5s;

				li {
					margin: 8px 0;
					text-align: center;
					cursor: pointer;

					a {
						color: #616784;

						&:hover {
							color: #f4303f;
						}

					}

					.active {
						color: #f4303f;
					}
				}
			}

			&:hover,
			&:focus {
				ul.nav-sub-zh {
					display: block;
					margin-top: 21px;
					opacity: 1;
				}
			}

			ul.nav-sub {
				position: absolute;
				left: 0px;
				top: 37.8px;
				display: none;
				width: 138px;
				line-height: 35px;
				box-shadow: 0 1.5px 20px 0 rgb(0 0 0 / 16%);
				background-color: #fff;
				opacity: 0;
				transition: 0.5s;

				li {
					margin: 8px 0;
					text-align: center;
					cursor: pointer;

					a {
						color: #616784;

						&:hover {
							color: #f4303f;
						}

					}

					.active {
						color: #f4303f;
					}
				}
			}

			&:hover,
			&:focus {
				ul.nav-sub {
					display: block;
					margin-top: 21px;
					opacity: 1;
				}
			}
		}

		.product_link.active {
			border-bottom: 3px solid #f4303f;
			color: #f4303f;
			cursor: pointer;
		}

		.product_link:hover {
			border-bottom: 3px solid #f4303f;
			color: #f4303f;
			cursor: pointer;
		}

    .logo-container {
      position: relative;
      width: 256px;
      height: 96px;
      margin: 0 31px;

			&::before {
				content: "";
				display: block;
				width: 256px;
				height: 200px;
				margin-top: -83px;
				background: #fff;
				box-shadow: 0 1.5px 20px 0 rgba(0, 0, 0, 0.16);
				transform: perspective(42px) rotateX(-6deg);
				text-align: center;
			}

			img {
				position: absolute;
				width: 145px;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
			}
		}

		.lang-switcher {
			position: relative;
			display: flex;
			width: 128px;
			height: 28px;
			background-color: #f0f2f7;
			border-radius: 20px;

			li {
				width: 50%;
				height: 100%;
				margin: 0;

				a {
					position: relative;
					z-index: 2;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 100%;
					font-family: SairaBold;
					font-size: 13px;
					color: #616784;
					text-decoration: none;
					transition: 0.5s;

					img {
						width: 16px;
						height: 16px;
					}

					span {
						padding-left: 7px;
					}

					&:hover {
						color: #616784;
					}

					&.active {
						color: #fff;
					}
				}
			}

			&:after {
				content: "";
				position: absolute;
				z-index: 1;
				top: 0;
				width: 50%;
				height: 100%;
				border-radius: 20px;
				background-color: #26325c;
				box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
				transition: 0.5s;
			}

			&.zh {
				&:after {
					left: 0;
				}
			}

			&.en {
				&:after {
					left: 50%;
				}
			}
		}
	}
}

.header:after {
  width: 100%;
  top: 0;
  left: 0;
}

// desktop responsive
@media screen and (max-width: 1536px) {
	header nav ul li div a.active {
		padding: 17px 0px;
	}

	header nav ul li div a:hover {
		padding: 17px 0px;
	}

	header nav ul li ul.nav-sub {
		top: 23.7px;
	}
}

@media screen and (max-width: 1460px) {
  header nav ul li {
    margin: 2.2%;
  }

	header nav ul li div a.active {
		padding: 17px 0px;
	}

	header nav ul li div a:hover {
		padding: 17px 0px;
	}
}

@media screen and (max-width: 1400px) {
	header nav ul li a {
		font-size: 13px;
	}

	header nav .product_link {
		padding: 17.5px 30px;
		font-size: 13px;
	}

	header nav .product_link ul.nav-sub {
		top: 37.8px;
		width: 128px;
	}

	header nav .product_link ul.nav-sub-zh {
		top: 37.8px;
		width: 112px;
	}
}

@media screen and (max-width: 1350px) {
  header nav .logo-container::before {
    width: 230px;
    height: 200px;
    margin-top: -91px;
  }

  header nav ul li ul.nav-sub {
    left: -36px;
    width: 120px;
  }

  header nav .logo-container {
    width: 255px;
    height: 85px;
    margin: 0 10px;
  }

  header nav .logo-container img {
    width: 145px;
  }

	header nav ul li {
		min-width: 55px;
	}
}

@media screen and (max-width: 1256px) {
	header nav ul li div a.active {
		padding: 18px 0px;
	}

	header nav ul li div a:hover {
		padding: 18px 0px;
	}
}

@media screen and (max-width: 1250px) {
  header nav .logo-container::before {
    width: 210px;
    height: 200px;
    margin-top: -91px;
  }

  header nav .logo-container img {
    width: 140px;
  }
}

@media screen and (max-width: 1200px) {
	header nav ul li {
		min-width: 35px;
	}

	header nav .product_link {
		padding: 12px 30px;
	}

	header nav .product_link ul.nav-sub {
		top: 26px;
		width: 128px;
		line-height: 25px;
	}

	header nav .product_link ul.nav-sub-zh {
		top: 26px;
		width: 112px;
		line-height: 25px;
	}

  header nav ul li div a.active {
    padding: 11px 0px;
	}

	header nav ul li div a:hover {
		padding: 11px 0px;
  }

	header nav .logo-container::before {
		width: 190px;
		height: 200px;
		margin-top: -110px;
	}

	header nav ul li ul.nav-sub {
		left: -30px;
		width: 110px;
		top: 16.9px;
	}

	header nav .logo-container img {
		width: 115px;
	}

	header {
		height: 50px;
	}

	header nav .logo-container {
		height: 70px;
	}
}

@media screen and (max-width: 1150px) {
	header nav ul li a {
		font-size: 11px;
	}

	header nav .product_link {
		font-size: 11px;
	}

	header nav .product_link ul.nav-sub-zh {
		top: 23px;
		width: 105px;
		line-height: 22px;
	}

	header nav .product_link ul.nav-sub {
		top: 23px;
		width: 118px;
		line-height: 22px;
	}

	header nav ul li ul.nav-sub li {
		margin: 5px 0;
	}

	header nav ul li div a.active {
		padding: 11px 0px;
	}

	header nav ul li ul.nav-sub {
		left: -25px;
		width: 95px;
		top: 16.7px;
	}

	header nav ul li div a.active {
		padding: 12px 0px;
	}

	header nav ul li div a:hover {
		padding: 12px 0px;
	}

	header nav .lang-switcher li a {
		font-size: 11px;
	}
}

@media screen and (max-width: 1097px) {
  header nav ul li a {
    font-size: 11px;
  }
}

@media screen and (max-width: 1024px) {
  header nav .logo-container::before {
    width: 160px;
	height: 200px;
    margin-top: -111px;
  }

  header nav .logo-container img {
    width: 105px;
  }

  header nav ul li a {
    font-size: 10px;
  }

  header nav .lang-switcher li a {
    font-size: 10px;
  }

  header nav ul li {
    min-width: 20px;
  }
}

@media screen and (max-width: 1000px) {
  header nav .lang-switcher {
    width: 110px;
  }
}

@media screen and (max-width: 850px) {
  header {
    height: 40px;
  }

  header nav ul li a {
    font-size: 9px;
  }

  header nav .logo-container::before {
    width: 130px;
	height: 200px;
    margin-top: -126px;
  }

  header nav .logo-container img {
    width: 80px;
  }

  header nav .logo-container {
    height: 55px;
  }

  header nav .lang-switcher {
    width: 100px;
    height: 24px;
  }
}

@media screen and (max-width: 800px) {
  header nav ul li a {
    font-size: 8px;
  }

  header nav .lang-switcher {
    width: 95px;
    height: 20px;
  }
}
