.g-heading {
    .heading {
        margin-bottom: 15px;
        color: #26325c;
        font-size: 57px;
        font-family: SairaBold;
        font-weight: bold;
        text-transform: uppercase;
    }
    .sub-heading {
        display: inline-block;
        margin-bottom: 8px;
        padding: 0 16px;
        font-size: 26px;
        letter-spacing: 1.6px;
        line-height: 2;
        color: #fff;
        font-family: SairaMedium;
        background-color: #f4303f;
        text-transform: uppercase;
    }
}