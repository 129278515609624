@font-face {
    font-family: "Saira";
    src: url("../assets/fonts/Saira-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "SairaBold";
    src: url("../assets/fonts/Saira-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "SairaExtraBold";
    src: url("../assets/fonts/Saira-ExtraBold.ttf") format("truetype");
}
@font-face {
    font-family: "SairaMedium";
    src: url("../assets/fonts/Saira-Medium.ttf") format("truetype");
}