@media screen and (max-width: 1536px) {
  .services-wrap {
    max-width: 1150px;
  }

  .services-wrap .blocks .block-wrap .text-div-indent {
    text-indent: 0px;
  }

  .services-wrap .blocks .block-wrap p {
    text-indent: -9px;
    margin-left: 9px;
  }

  .services-wrap .blocks .block-wrap h3 {
    font-size: 24px;
    min-height: 50px;
  }

  .services-wrap .blocks .block-wrap .text_width {
    width: 75%;
  }

  .services-wrap .blocks .block-wrap p {
    font-size: 14px;
  }

  .services-wrap .blocks .block-wrap img {
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 1280px) {
  .services-wrap {
    max-width: 1050px;
  }

  .services-wrap .blocks .block-wrap p {
    text-indent: -8px;
    margin-left: 9px;
  }

  .services-wrap .blocks .block-wrap .text-div-indent {
    text-indent: -3px;
  }

  .services-wrap .blocks .block-wrap h3 {
    font-size: 21px;
  }
}

@media screen and (max-width: 1250px) {
  .services-wrap {
    max-width: 1055px;
  }

  .services-wrap .blocks .block-wrap .text_width {
    width: 78%;
  }

  .services-wrap .blocks .block-wrap p {
    font-size: 11px;
  }

  .services-wrap .blocks .block-wrap p {
    font-size: 13px;
  }

  .services-wrap .blocks .block-wrap h3 {
    font-size: 23px;
  }
}

@media screen and (max-width: 1100px) {
  .services-wrap {
    max-width: 900px;
  }

  .services-wrap .blocks .block-wrap p {
    text-indent: -6px;
    margin-left: 6px;
  }

  .services-wrap .blocks .block-wrap img {
    width: 50px;
    height: 50px;
  }

  .services-wrap .blocks .block-wrap h3 {
    font-size: 17px;
    min-height: 42px;
  }

  .services-wrap .blocks .block-wrap .text_width {
    width: 75%;
    text-indent: -6px;
    margin-left: 6px;
  }

  .services-wrap .blocks .block-wrap .text-div-indent {
    text-indent: -5px;
  }

  .services-wrap .blocks .block-wrap p {
    font-size: 10px;
  }
}

@media screen and (max-width: 900px) {
  .services-wrap {
    max-width: 780px;
  }

  .services-wrap .blocks .block-wrap h3 {
    font-size: 14px;
    min-height: 40px;
  }

  .services-wrap .blocks .block-wrap p {
    font-size: 9px;
  }
}

@media screen and (max-width: 800px) {
  .services-wrap {
    max-width: 680px;
  }

  .services-wrap .blocks .block-wrap h3 {
    min-height: 34px;
  }

  .services-wrap .blocks .block {
    padding: 4px;
  }

  .services-wrap .blocks .block-wrap h3 {
    font-size: 12px;
  }

  .services-wrap .blocks .block-wrap img {
    width: 40px;
    height: 40px;
  }

  .services-wrap .blocks .block-wrap p {
    font-size: 8px;
  }

  .services-wrap .g-heading .heading {
    font-size: 50px;
  }
}

@media screen and (max-width: 740px) {
  .services-wrap {
    max-width: 600px;
    padding-bottom: 20px;
  }

  .services-wrap .blocks .block-wrap {
    padding: 13px;
  }
}

@media screen and (max-width: 650px) {
  .services-wrap {
    max-width: 535px;
  }

  .services-wrap .blocks .block-wrap img {
    width: 30px;
    height: 30px;
  }

  .services-wrap .g-heading .heading {
    font-size: 40px;
  }
}

@media screen and (max-width: 600px) {
  .services-wrap .blocks .block {
    width: 50%;
  }

  .services-wrap .blocks .block-wrap .text-div-indent {
    text-indent: 1px;
  }

  .services-wrap .blocks .block-wrap .text_width {
    width: 73%;
    text-indent: -9px;
    margin-left: 9px;
  }

  .services-wrap .blocks .block:hover {
    transform: scale(1.0);
  }

  .services-wrap .blocks .block-wrap img {
    width: 50px;
    height: 50px;
  }

  .services-wrap .blocks .block-wrap h3 {
    font-size: 24px;
    min-height: 45px;
  }

  .services-wrap .blocks .block-wrap .text-id-8 {
    margin-bottom: 5px;
  }

  .services-wrap .blocks .block-wrap p {
    font-size: 14px;
    text-indent: -7px;
    margin-left: 7px;
  }

  .services-wrap .blocks .block-wrap {
    padding: 24px;
  }
}

@media screen and (max-width: 550px) {
  .services-wrap {
    max-width: 495px;
  }

  .services-wrap .blocks .block-wrap h3 {
    font-size: 21px;
  }

  .services-wrap .blocks .block-wrap p {
    font-size: 12px;
  }
}

@media screen and (max-width: 500px) {
  .services-wrap {
    max-width: 445px;
  }

  .services-wrap .blocks .block-wrap .text-div-indent {
    text-indent: -1px;
  }

  .services-wrap .blocks .block-wrap {
    padding: 20px;
  }

  .services-wrap .blocks .block-wrap .text_width {
    width: 70%;
    text-indent: -7px;
    margin-left: 7px;
  }

  .services-wrap .blocks .block-wrap p {
    font-size: 11px;
  }
}

@media screen and (max-width: 480px) {
  .services-wrap {
    max-width: 410px;
  }

  .services-wrap .blocks .block-wrap h3 {
    font-size: 18px;
  }

  .services-wrap .blocks .block-wrap p {
    font-size: 10px;
  }
}

@media screen and (max-width: 400px) {
  .services-wrap {
    max-width: 330px;
  }

  .services-wrap .blocks .block-wrap .text-div-indent {
    text-indent: -1px;
  }

  .services-wrap .blocks .block-wrap h3 {
    min-height: 35px;
  }

  .text_margin {
    margin-bottom: 0px;
  }

  .services-wrap .blocks .block-wrap .text_width {
    width: 83%;
    text-indent: -6px;
    margin-left: 6px;
  }

  .services-wrap .blocks .block-wrap img {
    width: 40px;
    height: 40px;
  }

  .services-wrap .blocks .block-wrap h3 {
    font-size: 15px;
  }

  .services-wrap .blocks .block-wrap {
    padding: 13px;
  }

  .services-wrap .g-heading .heading {
    font-size: 37px;
  }
}

@media screen and (max-width: 360px) {
  .services-wrap {
    max-width: 305px;
  }

  .services-wrap .blocks .block-wrap .text-div-indent {
    text-indent: 0px;
  }

  .services-wrap .blocks .block-wrap .text_width {
    width: 73%;
    text-indent: -5px;
    margin-left: 6px;
  }

  .services-wrap .blocks .block-wrap h3 {
    min-height: 30px;
  }

  .g-heading .heading {
    font-size: 35px;
  }

  .services-wrap .blocks .block-wrap img {
    width: 30px;
    height: 30px;
  }

  .services-wrap .blocks .block-wrap h3 {
    font-size: 13px;
  }

  .services-wrap .blocks .block-wrap p {
    font-size: 9px;
    text-indent: -5px;
    margin-left: 5px;
  }
}

@media screen and (max-width: 320px) {
  .services-wrap {
    max-width: 275px;
  }

  .services-wrap .g-heading .heading {
    font-size: 32px;
  }

  .services-wrap .blocks .block-wrap h3 {
    font-size: 12px;
    min-height: 25px;
  }

  .services-wrap .blocks .block-wrap {
    padding: 10px;
  }
}
