.mobile_about {
    &-container {
        width: 90%;
        margin: auto;

        .row {
            display: inline;
            width: 90%;
            margin: auto;

            &-img {
                height: 460px;

                img {
                    width: 100%;
                    object-fit: contain;
                }

                .hero-jump {
                    top: 75px;
                    position: absolute;
                    left: 90px;

                    img {
                        animation: up_down 1.8s infinite alternate;
                        width: 75%;
                        object-fit: contain;
                    }
                }
            }

            .g-heading {
                .g-heading .heading {
                    font-size: 50px;
                }

                .sub-heading {
                    font-size: 20px;
                }

                .desc {
                    font-size: 16px;
                    text-align: justify;
                    text-justify: inter-word;
                }
            }
        }
    }

    @keyframes up_down {
        from {
            transform: translateY(5%)
        }

        to {
            transform: translateY(0)
        }
    }
}

@media screen and (max-width: 600px) {
    .mobile_about-container .row-img {
        height: 410px;
    }
}

@media screen and (max-width: 500px) {
    .mobile_about-container .row-img {
        height: 350px;
    }

    .mobile_about-container .row-img .hero-jump img {
        width: 73%;
    }

    .mobile_about-container .row .g-heading .heading {
        font-size: 45px;
    }

    .mobile_about-container .row .g-heading .sub-heading {
        font-size: 18px;
    }

    .mobile_about-container .row .g-heading .desc {
        font-size: 14px;
    }
}

@media screen and (max-width: 450px) {
    .mobile_about-container .row-img {
        height: 325px;
    }
}

@media screen and (max-width: 400px) {
    .mobile_about-container .row-img .hero-jump {
        left: 67px;
    }
}

@media screen and (max-width: 380px) {
    .mobile_about-container .row .g-heading .desc {
        font-size: 13px;
    }

    .mobile_about-container .row-img {
        height: 295px;
    }

    .mobile_about-container .row .g-heading .heading {
        font-size: 40px;
    }

    .mobile_about-container .row .g-heading .sub-heading {
        font-size: 15px;
    }
}

@media screen and (max-width: 340px) {
    .mobile_about-container .row-img {
        height: 250px;
    }
}