@media screen and (max-width: 1680px) {
  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInputInput {
    width: 87% !important;
  }
}

@media screen and (max-width: 1536px) {
  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInputInput {
    width: 86% !important;
  }
}

@media screen and (max-width: 1440px) {
  .contact-box-wrap-right .heading {
    font-size: 50px;
  }

  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInputInput {
    width: 85% !important;
  }

  .contact-box-wrap-right {
    padding-top: 50px;
  }
}

@media screen and (max-width: 1300px) {
  .contact-box-wrap-right {
    float: right;
  }

  .contact-box-wrap-right form input {
    width: 97%;
  }

  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInputInput {
    width: 84% !important;
  }

  .contact-box-wrap-right form .details .form-phone {
    width: 95%;
  }

  .contact-box-wrap-right form textarea {
    width: 97%;
  }

  .contact-box-wrap-right form textarea {
    height: 105px;
  }

  .contact-box-wrap-right form .details .form-phone .phone .number {
    width: 93%;
  }
}

@media screen and (max-width: 1256px) {
  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInputInput {
    width: 83% !important;
  }
}

@media screen and (max-width: 1250px) {
  .contact-box-wrap-right form .code {
    left: 29px;
  }
}

@media screen and (max-width: 1200px) {

  .contact-box-wrap-left .div-inside-image .contacts ul li img {
    max-width: 75px;
  }

  .PhoneInputCountryIcon--border {
    width: 18px !important;
    height: 14px !important;
  }

  .contact-box-wrap-right form .details .form-phone .phone .number {
    width: 92%;
  }

  .contact-box-wrap-right form .code {
    left: 23px;
  }

  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInput .PhoneInputCountrySelectArrow {
    width: 4px;
    height: 4px;
  }

  .contact-box-wrap-left .div-inside-image h1 {
    font-size: 22px;
  }

  .contact-box-wrap-right .heading {
    font-size: 50px;
  }
}

@media screen and (max-width: 1190px) {
  .contact-box-wrap-right form input {
    padding: 8px;
    font-size: 12px;
  }

  .contact-box-wrap-right form .code {
    transform: translate(7px, 7px);
    font-size: 11px;
    width: 31px;
  }

  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInput .PhoneInputCountry {
    padding: 5px;
    height: 25px;
  }

  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInputInput {
    width: 85% !important;
  }

  .contact-box-wrap-right form .details .form-phone {
    width: 94.5%;
  }

  .contact-box-wrap-right form .details .form-phone label {
    font-size: 11px;
  }

  .react-tel-input .form-control {
    font-size: 11px !important;
    height: 30px !important;
  }

  .contact-box-wrap-right form .details .form-phone .phone .number {
    width: 93%;
  }

  .contact-box-wrap-right form textarea {
    width: 96.5%;
  }

  .contact-box-wrap-right form button {
    padding: 8px;
  }
}

@media screen and (max-width: 1100px) {
  .contact-box-wrap-right .heading {
    font-size: 45px;
  }

  .contact-box-wrap-right form .details .form-phone {
    height: 112px;
  }

  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInputInput {
    width: 84% !important;
  }

  .contact-box-wrap-left .div-inside-image .contacts ul li img {
    max-width: 65px;
  }

  .contact-box-wrap-right form .details .form-phone .phone .number {
    width: 92%;
  }

  .contact-box-wrap-left .div-inside-image .site_number {
    font-size: 19px;
  }

  .contact-box-wrap-right form button {
    padding: 7px;
  }

  .contact-box-wrap-left .div-inside-image h1 {
    font-size: 20px;
    transform: translate(0px, 10px);
  }

  .contact-box-wrap-left .div-inside-image .message {
    height: 40px;
  }

  .contact-box-wrap-left .div-inside-image .call {
    height: 40px;
    margin-right: 0;
  }

  .contact-box-wrap-left .div-inside-image .phone-num {
    max-width: 183px;
    transform: translate(10px, 35px);
    font-size: 20px;
  }

  .contact-box-wrap-left .inside-image {
    top: 30px;
  }

  .contact-box-wrap-left .div-inside-image .div-m-call {
    transform: translate(0px, 20px);
  }

  .contact-box-wrap-right form textarea {
    height: 80px;
  }

  .react-tel-input .form-control {
    max-width: 86px;
  }

  .react-tel-input .selected-flag .arrow {
    left: 63px !important;
  }
}

@media screen and (max-width: 1024px) {
  .contact-box-wrap-right .heading {
    font-size: 40px;
  }

  .contact-box-wrap-left .div-inside-image .div-m-call {
    max-width: 150px;
  }

  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInputInput {
    width: 82% !important;
  }
}

@media screen and (max-width: 1000px) {
  .contact-box-wrap-left .div-inside-image h1 {
    font-size: 18px;
  }

  .contact-box-wrap-left .div-inside-image .site_number {
    font-size: 18px;
  }

  .contact-box-wrap-left .div-inside-image .contacts ul li img {
    max-width: 60px;
  }

  .contact-box-wrap-left .div-inside-image .message {
    height: 35px;
  }

  .contact-box-wrap-left .div-inside-image .call {
    height: 35px;
  }

  .contact-box-wrap-right form .details .form-phone {
    width: 93.5%;
  }

  contact-box-wrap-left .div-inside-image .div-m-call {
    max-width: 126px;
  }

  .contact-box-wrap-right form textarea {
    margin-bottom: 4px;
    height: 60px;
  }

  .contact-box-wrap-right form .details span {
    font-size: 11px;
  }

  .contact-box-wrap-right form button a {
    font-size: 13px;
  }

  .contact-box-wrap-right form .btn-div {
    max-width: 150px;
  }

  .contact-box-wrap-right form button {
    padding: 5px;
  }

  .contact-box-wrap-right .heading {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 950px) {
  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInputInput {
    width: 80% !important;
  }

  .contact-box-wrap-right form input {
    width: 95%;
  }

  .contact-box-wrap-right form .details .form-phone {
    width: 91.5%;
  }

  .contact-box-wrap-right form .details .form-phone .phone .number {
    width: 90%;
  }

  .react-tel-input .form-control {
    max-width: 68px;
  }

  .react-tel-input .flag {
    width: 13px;
    height: 10px;
  }

  .react-tel-input .form-control {
    padding-left: 27px !important;
  }

  .react-tel-input .selected-flag .arrow {
    left: 48px !important;
  }

  .contact-box-wrap-right form textarea {
    width: 94%;
  }
}

@media screen and (max-width: 900px) {
  .contact-box-wrap-left .div-inside-image h1 {
    font-size: 17px;
    max-width: 195px;
  }

  .contact-box-wrap-left .div-inside-image .contacts ul li img {
    max-width: 55px;
  }

  .contact-box-wrap-left .div-inside-image .phone-num {
    transform: translate(16px, 34px);
    font-size: 18px;
  }

  .contact-box-wrap-left .div-inside-image .div-m-call {
    max-width: 127px;
  }

  .contact-box-wrap-left .div-inside-image .site_number {
    font-size: 17px;
  }

  .contact-box-wrap-right form .details .form-phone {
    padding: 10px;
    width: 93.5%;
  }

  .contact-box-wrap-right form button a {
    font-size: 12px;
  }

  .contact-box-wrap-right form .btn-div {
    max-width: 135px;
  }

  .contact-box-wrap-left .side-image {
    height: 554px;
  }

  .contact-box-wrap-right form input {
    margin-bottom: 7px;
  }

  .contact-box-wrap-right form .details .form-phone .phone .number {
    width: 90.5%;
  }
}

@media screen and (max-width: 850px) {
  .contact-box-wrap-right form .btn-div {
    max-width: 170px;
  }

  .contact-box-wrap-right .heading {
    font-size: 35px;
  }

  .contact-box-wrap-left .div-inside-image .contacts {
    padding-top: 10px;
  }

  .contact-box-wrap-right form .details .form-phone .phone .number {
    width: 89.5%;
  }

  .contact-box-wrap-left .div-inside-image h1 {
    font-size: 18px;
    width: 41%;
    transform: translate(0px, 20px);
  }

  .contact-box-wrap-left .div-inside-image .phone-num {
    transform: translate(16px, 50px);
    font-size: 18px;
  }

  .contact-box-wrap-left .div-inside-image .div-m-call {
    transform: translate(0px, 30px);
  }
}

@media screen and (max-width: 840px) {
  .contact-box-wrap-left .div-inside-image h1 {
    width: 42%;
  }
}

@media screen and (max-width: 800px) {
  .contact-box-wrap-left .inside-image {
    width: 95%;
    margin-left: 10px;
    top: 40px;
  }

  .contact-box-wrap-left .div-inside-image h1 {
    width: 43%;
    transform: translate(0px, 35px);
  }

  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInputInput {
    width: 76% !important;
  }

  .contact-box-wrap-left .div-inside-image .div-m-call {
    transform: translate(0px, 45px);
  }

  .contact-box-wrap-left .div-inside-image .contacts {
    padding-top: 25px;
  }

  .contact-box-wrap-left .div-inside-image .phone-num {
    transform: translate(16px, 70px);
  }
}

@media screen and (max-width: 700px) {
  .contact-box-wrap {
    flex-direction: column-reverse;
  }

  .contact-box-wrap-right .div_succes {
    padding: 50px;
    margin-bottom: 70px;
  }

  .contact-box-wrap-right {
    width: 90%;
    padding-top: 70px;
  }

  .contact-box-wrap-right form textarea {
    width: 94.4%;
  }

  .contact-box-wrap-left {
    width: 100%;
  }

  .contact-box-wrap-right form .details .form-phone {
    width: 94.5%;
  }

  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInputInput {
    width: 88% !important;
  }

}

@media screen and (max-width: 580px) {
  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInputInput {
    width: 86% !important;
  }
}


@media screen and (max-width: 480px) {
  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInputInput {
    width: 83% !important;
  }
}

@media screen and (max-width: 400px) {
  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInputInput {
    width: 82% !important;
  }

  .contact-box-wrap-right .heading {
    font-size: 30px;
  }

  .contact-box-wrap-right .div_succes .success_btn {
    padding: 8px;
    width: 190px;
    font-size: 18px;
  }

  #message {
    font-size: 15px;
  }
}

@media screen and (max-width: 380px) {
  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInputInput {
    width: 78% !important;
  }
}

@media screen and (max-width: 360px) {
  .contact-box-wrap-left .div-inside-image h1 {
    width: 80%;
  }

  .contact-box-wrap-right {
    width: 85%;
  }

  .contact-box-wrap-right .div_succes {
    padding: 20px;
  }
}

@media screen and (max-width: 320px) {
  .contact-box-wrap-right form .details .form-phone .phone .container .inline .PhoneInputInput {
    width: 76% !important;
  }
}