.float__Content {
    position: fixed;
    right: 1%;
    bottom: 0;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;

    &-desktop {
        width: 60%;
        margin-left: auto;
        animation: object 2s ease infinite;

        &-img {
            img {
                width: 100%;
                display: block;
            }
        }

        &-demo_url {
            margin-bottom: 3%;

            a {
                display: inline-block;
                width: 100%;
                text-align: left;
                box-shadow: 0 3px 7.7px 1.4px rgb(29 5 5 / 35%);
                border-left: 2px solid #f4303f;
                border-top: transparent;
                border-right: transparent;
                border-bottom: transparent;
                background-color: #ffffff;
                color: #f4303f;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 900;
                padding: 10px 0px 10px 10px;
                text-decoration: none;
            }
        }

        &-to-know-more {
            float: right;
            width: 100%;
            margin-bottom: 4px;
            right: 0px;
            z-index: 2;

            a {

                display: inline-block;
                width: 100%;
                text-align: left;
                box-shadow: 0 3px 7.7px 1.4px rgb(29 5 5 / 35%);
                border-left: 2px solid #f4303f;
                border-top: transparent;
                border-right: transparent;
                border-bottom: transparent;
                background-color: #ffffff;
                color: #f4303f;
                text-transform: uppercase;
                font-weight: 900;
                padding: 10px 0px 10px 10px;
                font-size: 14px;
                text-decoration: none;
            }
        }

        &-demo_url,
        &-to-know-more {
            position: relative;
            transform: translate(-7px, -406%);
            pointer-events: all;
            padding: 0px 24px;
            width: 77%;
        }

        &-demo_url:after,
        &-to-know-more:after {
            content: "";
            position: absolute;
            top: 50%;
            right: -22%;
            transition: .5s ease;
            transform: translateY(-50%);
            background-image: url("/assets/images/arrow-round.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 0;
            width: 38%;
            height: 38%;
            pointer-events: none;
        }

        @keyframes object {
            from {
                transform: translate(0, 0px);
            }

            65% {
                transform: translate(0, 15px);
            }

            to {
                transform: translate(0, -0px);
            }
        }
    }
}


// Mobile View
.float_mobile {
    position: fixed;
    right: 0%;
    bottom: 90px;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;

    &-icon {
        display: block;

        &-demo_url {
            a {
                margin-bottom: 3%;
                background: white;
                border-left: 3px solid red;
                pointer-events: all;
                padding: 0px 0px;
                max-width: 57px;
                height: 50px;
                display: flex;
                align-items: center;

                img {
                    width: 60%;
                    object-fit: contain;
                    padding: 10px;
                }
            }
        }

        &-to-know-more {
            a {
                margin-bottom: 3%;
                background: white;
                border-left: 3px solid red;
                pointer-events: all;
                padding: 0px 0px;
                max-width: 57px;
                height: 50px;
                display: flex;
                align-items: center;

                img {
                    width: 60%;
                    object-fit: contain;
                    padding: 10px;
                }
            }
        }



        @keyframes object {
            from {
                transform: translate(0, 0px);
            }

            65% {
                transform: translate(0, 15px);
            }

            to {
                transform: translate(0, -0px);
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .float__Content-desktop {
        width: 50%;
    }

    .float__Content-desktop-demo_url,
    .float__Content-desktop-to-know-more {
        transform: translate(-6px, -390%);
        pointer-events: all;
        padding: 0px 22px;
        width: 75%;
    }

    .float__Content-desktop-demo_url a {
        font-size: 13px;
        padding: 8px 0px 8px 8px;
    }

    .float__Content-desktop-to-know-more a {
        font-size: 13px;
        padding: 8px 0px 8px 8px;
    }
}