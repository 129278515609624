.mobile_banner {
    .slick-slider {
        .slick-slide {
            position: relative;
            width: 100%;
            height: 100%;

            .cover {
                width: 100%;
                object-fit: cover;
                height: auto;
            }
        }
    }

}
.banner-wrapper {
    position: relative;
    
    a.cert-area {
        position: absolute;
        bottom: 10%;
        right: 30%;
        height: 200px;
        width: 300px;
        max-width: 40%;
        max-height: 20%;
        background-color: transparent;
        cursor: pointer;
        z-index: 100;
    }
}


@media screen and (max-width: 1024px) {
    .banner .slick-slider .slick-slide .cover {
        object-fit: contain;
        background-attachment: fixed;
    }

    .banner .slick-slider .slick-slide .contents.slide-2 .sub-heading {
        top: 60%;
    }

    .banner .slick-slider .slick-slide .slide-1,
    .slide-2,
    .slide-3,
    .slide-4 {
        img {
            opacity: 0;
            transition: 0.3s;
        }
    }

    .banner .slick-slider .slick-dots {
        bottom: 90px !important;
    }

    .banner .slick-slider .slick-slide .contents.slide-4 .coins {
        top: 43.2%;
    }
}

@media screen and (max-width: 950px) {
    .banner .slick-slider .slick-dots {
        margin-left: 41.5% !important;
        width: 17% !important;
    }
}

@media screen and (max-width: 900px) {


    .banner .slick-slider .slick-dots {
        bottom: 75px !important;
    }

    .banner .slick-slider .slick-slide .contents.slide-4 .coins {
        top: 51.2%;
    }
}

@media screen and (max-width: 850px) {
    .banner .slick-slider .slick-dots {
        margin-left: 39.5% !important;
        width: 20% !important;
    }
}

@media screen and (max-width: 800px) {
    .banner .slick-slider .slick-slide .contents.slide-4 .coins {
        top: 57.2%;
    }

    .banner .slick-slider .slick-dots {
        bottom: 68px !important;
        margin-left: 40% !important;
    }

    .slick-dots li {
        width: 8px;
        height: 8px;
    }
}

@media screen and (max-width: 700px) {
    .banner .slick-slider .slick-slide .contents.slide-4 .coins {
        top: 62.2%;
    }

    .banner .slick-slider .slick-dots {
        margin-left: 37% !important;
        width: 25% !important;
    }
}

@media screen and (max-width: 600px) {
    .banner .slick-slider .slick-slide .cover {
        height: auto;
        object-fit: contain;
    }

    .banner .slick-slider .slick-slide .slide-1,
    .slide-2,
    .slide-3,
    .slide-4 {
        img {
            opacity: 0;
            transition: 0.3s;
        }
    }

    .banner .slick-slider .slick-dots {
        margin-left: 38% !important;
        width: 25% !important;
        height: 34px;
        bottom: 25px !important;
    }

    .slick-dots li {
        width: 18px;
    }
}

@media screen and (max-width: 570px) {
    .banner .slick-slider .slick-dots {
        margin-left: 35% !important;
        width: 30% !important;
    }
}

@media screen and (max-width: 480px) {
    .banner .slick-slider .slick-dots {
        margin-left: 32% !important;
        width: 35% !important;
    }
}

@media screen and (max-width: 440px) {
    .slick-dots li {
        width: 8px;
    }
}

@media screen and (max-width: 400px) {
    .banner .slick-slider .slick-dots {
        margin-left: 30% !important;
        width: 40% !important;
    }
}

@media screen and (max-width: 360px) {
    .banner .slick-slider .slick-dots {
        margin-left: 26% !important;
        width: 45% !important;
        height: 34px;
        bottom: 22px !important;
    }
}

@media screen and (max-width: 320px) {
    .banner .slick-slider .slick-dots {
        margin-left: 25% !important;
        width: 50% !important;
    }
}
