.banner {
    position: relative;
    overflow: hidden;
    background-color: #f5f8fa;
    .banner-1 {
        position: relative;
        
        a.cert-area {
            position: absolute;
            bottom: 5%;
            right: 2%;
            height: 200px;
            width: 300px;
            max-width: 20%;
            max-height: 20%;
            background-color: transparent;
            cursor: pointer;
            z-index: 100;
        }
    }
    .slick-slider {

        .slick-slide {
            position: relative;
            width: 100%;
            height: 100%;

            .cover {
                width: 100%;
            }

            .contents {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;

                &.slide-1,
                &.slide-2,
                &.slide-2-zh,
                &.slide-3,
                &.slide-4 {
                    img {
                        opacity: 0;
                        transition: 0.6s;
                    }
                }

                &.slide-1 {
                    .heading {
                        position: absolute;
                        width: 56%;
                        top: 35%;
                        left: -30%;
                        right: 0;
                        margin: auto;
                    }

                    .sub-heading {
                        position: absolute;
                        top: 48%;
                        left: -40px;
                        right: -30%;
                        width: 52%;
                        margin: auto;

                        &:after {
                            content: "";
                            position: absolute;
                            top: -50%;
                            left: -60%;
                            width: 20%;
                            height: 200%;

                            transform: rotate(30deg);

                            background: rgba(255, 255, 255, 0.13);
                            background: linear-gradient(to right,
                                    rgba(255, 255, 255, 0.13) 0%,
                                    rgba(255, 255, 255, 0.13) 77%,
                                    rgba(255, 255, 255, 0.5) 92%,
                                    rgba(255, 255, 255, 0.0) 100%);
                        }

                        &:hover:after {
                            opacity: 1;
                            left: 130%;
                            transition-property: left, top, opacity;
                            transition-duration: 2s, 2s, 2s;
                            transition-timing-function: ease;
                        }
                    }
                }

                &.slide-2 {
                    .title {
                        position: absolute;
                        width: 30%;
                        top: 21%;
                        left: 0;
                        right: 0;
                        margin: auto;
                        z-index: 2;
                    }

                    .heading {
                        position: absolute;
                        width: 65%;
                        top: 37%;
                        left: -5%;
                        right: 0;
                        margin: auto;
                    }

                    .sub-heading {
                        position: absolute;
                        width: 50%;
                        top: 61%;
                        left: 13%;
                        right: 0;
                        margin: auto;
                    }
                }

                &.slide-2-zh {
                    .title {
                        position: absolute;
                        width: 23%;
                        top: 21%;
                        left: 0;
                        right: 0;
                        margin: auto;
                        z-index: 2;
                    }

                    .heading {
                        position: absolute;
                        width: 35%;
                        top: 35%;
                        left: -5%;
                        right: 0;
                        margin: auto;
                    }

                    .sub-heading {
                        position: absolute;
                        width: 47%;
                        top: 50%;
                        left: 13%;
                        right: 0;
                        margin: auto;
                    }
                }

                &.slide-3 {
                    .title {
                        position: absolute;
                        width: 28%;
                        top: 41%;
                        left: 54%;
                        z-index: 1;
                    }

                    .heading {
                        position: absolute;
                        width: 49%;
                        top: 58%;
                        left: 20%;
                        z-index: 1;
                    }

                    .sub-heading {
                        position: absolute;
                        width: 52%;
                        top: 67%;
                        left: 58%;
                        z-index: 1;
                    }

                    .loot1 {
                        position: absolute;
                        width: 53%;
                        top: 0%;
                        left: 2.5%;
                        transition: 0.4s;
                    }

                    .loot2 {
                        position: absolute;
                        width: 50%;
                        top: 10%;
                        left: 7.7%;
                        transition: 0.6s;
                    }

                    .loot3 {
                        position: absolute;
                        width: 53%;
                        top: 22%;
                        left: 12.5%;
                        transition: 0.8s;
                    }
                }

                &.slide-4 {
                    .title {
                        position: absolute;
                        top: 24%;
                        left: 3%;
                        width: 29%;
                        z-index: 2;
                    }

                    .zh-title {
                        position: absolute;
                        top: 24%;
                        left: 3%;
                        width: 29%;
                        z-index: 2;
                    }

                    .heading {
                        position: absolute;
                        z-index: 1;
                        top: 34%;
                        left: 3%;
                        width: 74%;
                    }

                    .sub-heading {
                        position: absolute;
                        top: 90%;
                        left: 22%;
                        width: 52%;
                    }

                    .loot-1 {
                        position: absolute;
                        width: 39.5%;
                        right: -5%;
                        top: 13.5%;
                    }

                    .loot-2 {
                        position: absolute;
                        width: 9.6%;
                        right: 3%;
                        top: 46%;
                    }

                    .btc {
                        position: absolute;
                        top: 62%;
                        left: 31%;
                        width: 10%;
                    }

                    .eth {
                        position: absolute;
                        top: 63%;
                        left: 43%;
                        width: 11%;
                    }

                    .usdt {
                        position: absolute;
                        top: 64%;
                        left: 56%;
                        width: 10%;
                    }

                    .coins {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                    }
                }
            }

            &.slick-active {
                .contents {

                    &.slide-1,
                    &.slide-2,
                    &.slide-2-zh,
                    &.slide-3,
                    &.slide-4 {
                        img {
                            opacity: 1;
                        }
                    }

                    &.slide-1 {
                        .heading {
                            left: 0;
                        }

                        .sub-heading {
                            right: 0;
                        }
                    }

                    &.slide-2 {
                        .heading {
                            left: 2%;
                        }

                        .sub-heading {
                            left: -2%;
                        }
                    }

                    &.slide-2-zh {
                        .heading {
                            left: 2%;
                        }

                        .sub-heading {
                            left: 1%;
                        }
                    }

                    &.slide-3 {
                        .heading {
                            left: 43%;
                        }

                        .sub-heading {
                            left: 41%;
                        }

                        .loot1 {
                            top: 4%;
                        }

                        .loot2 {
                            top: 15%;
                        }

                        .loot3 {
                            top: 27%;
                        }
                    }

                    &.slide-4 {
                        .title {
                            left: 13%;
                        }

                        .zh-title {
                            left: 10%;
                        }

                        .heading {
                            left: 13%;
                        }

                        .loot-1 {
                            right: 0;
                        }

                        .loot-2 {
                            top: 49.5%;
                        }
                    }
                }
            }
        }

        .slick-dots {
            bottom: 165px !important;
            border-radius: 50px !important;
            background-color: #26325c40 !important;
            height: 35px;
            width: 10% !important;
            margin-left: 45% !important;

            li.slick-active button:before {
                opacity: .75;
                color: white;
            }

            li {
                width: 10px !important;
                height: 10px !important;
                margin: 0 12px !important;

                button {
                    border: 1px solid white !important;
                    border-radius: 50% !important;
                    display: block;
                    height: 10px !important;
                    width: 10px !important;
                }

                button:before {
                    font-family: 'slick';
                    font-size: 12px;
                    line-height: 15px !important;
                    height: 10px !important;
                    width: 10px !important;
                }
            }

            .slick {
                &-active {
                    background: white !important;
                    border-radius: 50%;
                    width: 12px !important;
                    height: 12px !important;
                }
            }
        }
    }

    .scroll-icon {
        position: absolute;
        bottom: 4%;
        right: 0;
        left: 0;
        width: 5%;
        margin: auto;
        animation-name: float-scroll-icon;
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }

    @keyframes float-scroll-icon {
        0% {
            bottom: 4%;
        }

        50% {
            bottom: 2%;
        }

        100% {
            bottom: 4%;
        }
    }
}

@media screen and (max-width: 1536px) {
    .banner .slick-slider .slick-dots {
        bottom: 140px !important;
    }
}

@media screen and (max-width: 1380px) {
    .banner .slick-slider .slick-dots {
        width: 13% !important;
        margin-left: 43% !important;
    }
}

@media screen and (max-width: 1350px) {
    .banner .slick-slider .slick-dots {
        bottom: 123px !important;
    }

    .banner .slick-slider .slick-dots {
        margin-left: 44% !important;
    }
}

@media screen and (max-width: 1250px) {
    .banner .slick-slider .slick-dots {
        bottom: 111px !important;
    }
}

@media screen and (max-width: 1150px) {
    .slick-dots li {
        width: 10px;
        height: 10px;
    }

    .slick-dots li button:before {
        line-height: 15px;
        width: 10px;
        height: 10px;
    }

    .banner .slick-slider .slick-dots {
        bottom: 105px !important;
    }

    .banner .slick-slider .slick-slide .slide-1,
    .slide-2,
    .slide-3,
    .slide-4 {
        img {
            opacity: 0;
            transition: 0.4s;
        }
    }
}

@media screen and (max-width: 1100px) {
    .banner .slick-slider .slick-dots {
        margin-left: 43.5% !important;
    }

    .banner .slick-slider .slick-dots {
        bottom: 95px !important;
    }
}

@media screen and (max-width: 1080px) {
    .banner .slick-slider .slick-dots {
        margin-left: 42.5% !important;
        width: 15% !important;
    }
}
