.services {
  padding-top: 64px;
  background-color: #f5f8fa;

  &-wrap {
    max-width: 1536px;
    width: 100%;
    margin: auto;
    padding-bottom: 100px;

    .title {
      text-align: center;
    }

    .g-heading {
      .heading {
        margin-bottom: 15px;
        color: #26325c;
        font-size: 57px;
        font-family: SairaBold;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .blocks {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      .block {
        width: 25%;
        padding: 10px;
        text-align: left;
        box-sizing: border-box;
        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }

        &-wrap {
          width: 100%;
          height: 100%;
          padding: 26px;
          box-sizing: border-box;
          background-color: #fff;
          border-radius: 10px;
          box-shadow: 0 1.5px 15px 0 rgba(152, 161, 190, 0.2);

          img {
            width: 80px;
            height: 80px;
            margin-bottom: 13px;
          }

          h3 {
            min-height: 70px;
            margin-bottom: 16px;
            font-family: SairaBold;
            line-height: 1.2;
            font-weight: 900;
            font-size: 34px;
            color: #26325c;
            text-transform: capitalize;
          }

          .text_margin {
            margin-bottom: 0px;
            line-height: 1.4;
          }

          .text-id-8 {
            line-height: 1.4;
          }

          p {
            font-size: 18px;
            color: #26325c;
            text-indent: -11px;
            margin-left: 12px;
            width: 100%;
            text-transform: capitalize;
          }

          .text_width {
            width: 76%;
          }

          .text-div-indent {
            text-indent: 2px;
          }

          .span_disabled {
            display: none;
          }
        }
      }
    }
  }
}
