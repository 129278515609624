.mobile {
    background: transparent;
    box-shadow: none;

    .cont {
        width: 90%;
        display: table;
        margin: auto;
        padding: 20px 0;
    }

    &-logo {
        display: table-cell;
        width: 10%;
        min-width: 120px;
        vertical-align: middle;

        img {
            display: block;
            object-fit: contain;
            width: 100%;
        }
    }

    &-links {
        display: table-cell;
        vertical-align: middle;
    }

    &-switch {

        .lang-switcher {
            position: fixed;
            display: flex;
            width: 128px;
            height: 28px;
            right: 13%;
            top: 15px;
            background-color: #f0f2f7;
            border-radius: 20px;

            li {
                width: 50%;
                height: 100%;
                margin: 0;

                a {
                    position: relative;
                    z-index: 2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    font-family: SairaBold;
                    font-size: 13px;
                    color: #616784;
                    text-decoration: none;
                    transition: 0.5s;

                    img {
                        width: 16px;
                        height: 16px;
                    }

                    span {
                        padding-left: 7px;
                        text-transform: uppercase;
                    }

                    &:hover {
                        color: #616784;
                    }

                    &.active {
                        color: #fff;
                    }
                }
            }

            &:after {
                content: "";
                position: absolute;
                z-index: 1;
                top: 0;
                width: 50%;
                height: 100%;
                border-radius: 20px;
                background-color: #26325c;
                box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
                transition: 0.5s;
            }

            &.zh {
                &:after {
                    left: 0;
                }
            }

            &.en {
                &:after {
                    left: 50%;
                }
            }
        }
    }

    .hamburger {
        display: table-cell;
        vertical-align: middle;
        padding-left: 30px;
        position: fixed;
        z-index: 100;
        max-width: 100vw;
        top: 34px;
        right: 40px;
        cursor: pointer;

        &-btn {
            cursor: pointer;
            padding-top: 20px;
            width: 96%;
            font-size: 35px;
            text-align: right;
        }

        &-img-bottom {
            position: absolute;
            bottom: 0;
            width: 100%;
            margin-bottom: 20px;

            img {
                width: 20%;
                object-fit: contain;
            }
        }

        img {
            display: block;
            object-fit: contain;
            width: 80%;
        }

        &-menu {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            pointer-events: all;
            z-index: 99;

            &-container {
                text-align: left;
                width: 100%;
                height: 100%;
                margin: auto;
                position: relative;
                box-shadow: 0 1.5px 20px 0 rgb(0 0 0 / 16%);
                background-image: linear-gradient(to right, rgb(255 255 255 / 38%), rgb(255 255 255));

                a {
                    color: black;
                    text-decoration: none;
                    font-size: 24px;
                    right: 45px;
                    font-weight: bold;
                    position: relative;
                    text-transform: uppercase;
                    display: block;
                    text-align: right;
                    margin: 16px 0;
                    transition: .3s ease;
                    transform: translateY(15px);
                }

                .span-arrows {
                    font-size: 15px;
                    left: 10px;
                    top: 0px;
                    position: relative;
                }

                div.nav-sub {
                    height: 105px;
                    width: 100%;
                    position: relative;
                    transition: height 0.5s;
                    -webkit-transition: height 0.5s;
                    text-align: center;
                    overflow: hidden;

                    a {
                        color: #000000;
                        position: relative;
                        right: 58px;
                        margin-top: 2px;

                        &:hover {
                            color: #f4303f;
                        }
                    }

                    a.active {
                        color: #f4303f;
                    }
                }

                a.active {
                    color: #f4303f;
                }

                a:hover {
                    color: #f4303f;
                }

            }

        }
    }

}

@media screen and (max-width: 700px) {
    .mobile-switch .lang-switcher {
        right: 15%;
    }
}

@media screen and (max-width: 600px) {
    .mobile-switch .lang-switcher {
        right: 18%;
    }

    .mobile .hamburger-img-bottom img {
        width: 25%;
    }
}

@media screen and (max-width: 500px) {
    .mobile .hamburger-menu-container a {
        font-size: 20px;
    }

    .mobile .hamburger {
        right: 20px;
    }

    .mobile-switch .lang-switcher {
        width: 125px;
    }
}

@media screen and (max-width: 450px) {
    .mobile-switch .lang-switcher {
        right: 20%;
    }

    .mobile .hamburger-img-bottom img {
        width: 30%;
    }
}

@media screen and (max-width: 400px) {
    .mobile .hamburger img {
        width: 65%;
    }

    .mobile .hamburger {
        right: 5px;
    }

    .mobile-switch .lang-switcher {
        right: 17%;
        width: 110px;
        height: 25px;
    }

    .mobile-switch .lang-switcher li a {
        font-size: 11px;
    }
}

@media screen and (max-width: 380px) {
    .mobile-switch .lang-switcher li a {
        font-size: 11px;
    }

    .mobile .hamburger img {
        width: 70%;
    }

    .mobile-logo img {
        width: 85%;
    }

    .mobile .hamburger-img-bottom img {
        width: 35%;
    }

}

@media screen and (max-width:340px) {
    .mobile .hamburger img {
        width: 60%;
    }

    .mobile .hamburger {
        right: 0px;
    }

    .mobile-switch .lang-switcher {
        width: 90px;
        right: 18%;
        height: 23px;
    }

    .mobile-switch .lang-switcher li a img {
        width: 10px;
        height: 10px;
    }

    .mobile .hamburger-menu-container a {
        font-size: 17px;
        margin: 10px 0;
    }

    .mobile .hamburger-menu-container div.nav-sub {
        height: 77px;
    }
}