.modal_warning {
  position: fixed;
  inset: 0px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 100vw;

  &-open {
    inset: 0;
    overflow: auto;
    border-radius: 0px;
    margin: 80px auto;
    position: relative;
    background: transparent;
    border: none;
    padding: 20px;
    width: auto;
    outline: none;
    max-width: 1140px;
    box-sizing: border-box;
  }

  #popup {
    position: relative;
    width: auto;
    overflow-y: auto;
    height: 100vh;
  }

  img {
    display: block;
    object-fit: cover;
    width: 100%;
    margin: auto;
    position: relative;
    aspect-ratio: 244/135;
  }

  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    width: 32px;
    height: 32px;
    z-index: 1;
    cursor: pointer;
    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.display-none {
  display: none;
}



@media screen and (max-width: 1150px) {
  .modal_warning-open {
    width: 800px;
  }
}

@media screen and (max-width: 900px) {
  .modal_warning-open {
    width: 700px;
  }
}

@media screen and (max-width: 700px) {
  .modal_warning-open {
    position: relative;
    width: 100%;
    margin: 0;
  }
  .close-btn {
    position: fixed !important;
    right: 40px !important;
    top: 30px !important;
  }
  .modal_warning #popup {
    height: 95vh;
  }

  .modal_warning img {
    width: 100%;
    aspect-ratio: 563/1116;
  }
}
@media screen and (max-width: 320px){
  
  .ReactModal__Content h1 {
    width: 91%;
}
}